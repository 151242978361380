import React from "react";
import Navbar from "./Navbar";
import { StageSpinner } from "react-spinners-kit";
import parsePhoneNumber from "libphonenumber-js";
import {
  ElementsConsumer,
  CardElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import InstagramFeed from "react-ig-feed";
import {
  getCart,
  clearCart,
  setUser,
  getUser,
  clearUser,
  returnFormUrl,
  getLanguageFromCookie,
  getLanguageFromBrowser,
  getLanguageFromParams,
  translate,
  validEmailAddress,
  setCountrySelectorDefaultValue,
  containsRestrictedProducts,
  removeRestrictedItemsFromCart,
  setCart,
  isRestrictedProduct,
  calculateAmount,
  shippingAmount,
  itemSumAmount,
  totalAmount,
  vatTotalAmount,
  calculateDiscountCode,
  countryMapper,
  OPRA_DISCOUNT_CODE,
  capitalizeName,
  cartContainsCallsignsPack,
  cartContainsPickupPointOnlyItem,
} from "../utils";
import { itemsInCart } from "../utils/cartFunctions";
import {
  renderAmountDecimals,
  showExtendedShippingOptions,
  showExtendedPaymentOptions,
  showVippsPaymentOption,
  hasChangedShippingZones,
  instagramToken,
  supportedCountriesTrackedShippingBring,
  countryCodeMapper,
  //specialAddressCharacters,
} from "../utils/helperFunctions";
import {
  calculatePackageSize,
  calculatePackageWeight,
  createPackageDescription,
  splitAddress,
  isLongDistanceShipping,
  phoneNumberNotRequired,
} from "../utils/shipping";
import Strapi from "strapi-sdk-javascript/build/main";
import { withRouter } from "react-router-dom";
import Agreement from "./Agreement";
import DonationSection from "./DonationSection";
import { detect } from "detect-browser";
import {
  OPRA_DISCOUNT_PERCENTAGE,
  BLACK_FRIDAY_DISCOUNT,
} from "../utils/translations";
const isProduction = process.env.NODE_ENV === "production";
const strapiUrl = isProduction
  ? "https://sjanten-server.herokuapp.com"
  : "http://localhost:1337";
const strapi = new Strapi(strapiUrl);
let pr = null;
const siteUrl =
  process.env.NODE_ENV === "production"
    ? "https://www.3charlie.no"
    : "http://192.168.0.108:3000";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: "#ffffff",
      fontSize: "14px",
      lineHeight: "21px",
      fontFamily: "Allerta Stencil, Helvetica, sans-serif",
      fontSmoothing: "antialiased",
      color: "#ffffff",
      ":-webkit-autofill": {
        color: "#000000",
        backgroundColor: "#e8f0ff",
      },
      "::placeholder": {
        color: "#777777",
      },
    },
    complete: {
      iconColor: "#ffffff",
    },
    invalid: {
      iconColor: "#ff9999",
      color: "#ff9999",
    },
  },
};

class _CheckoutForm extends React.Component {
  userData;
  constructor(props) {
    super(props);
    this.state = {
      cartItems: [],
      summaryItems: [],
      firstName: "",
      lastName: "",
      address: "",
      postalCode: "",
      city: "",
      confirmationEmailAddress: "",
      phone: "",
      comment: "",
      giftcard: "",
      country: setCountrySelectorDefaultValue(),
      state: "",
      errorMessage: null,
      orderProcessing: false,
      modal: false,
      cardDetailsComplete: false,
      agreementChecked: false,
      showAgreement: false,
      showOrderConfirmation: false,
      showOrderError: false,
      scrollPos: 0,
      rememberMe: false,
      websiteLanguage: "no",
      showLongerDeliveryTime: false,
      paymentMethod: "card",
      redirectingToVipps: false,
      showVippsConfirmationStatus: false,
      vippsError: false,
      remainingGiftcardCredits: undefined,
      showDiscountInput: false,
      checkingDiscount: false,
      discountCode: "",
      discountType: "",
      discount: false,
      showDiscountMessage: undefined,
      discountMessage: "",
      shippingMethod: "mailbox",
      pickUpPoints: [],
      cachedPickUpPoints: undefined,
      loadingPickUpPoints: false,
      selectedPickUpPoint: undefined,
      deliveryTime: undefined,
      showRemoveRestrictedProductsButton: false,
      selectedCurrency: "NOK",
      currenciesAvailable: false,
      currencyRates: [],
      showShippingTag: false,
      loadingPriceFromApiMailbox: false,
      shippingPriceFromApiMailbox: undefined,
      expectedDeliveryDaysFromApiMailbox: undefined,
      validPostalCodeFromBringApi: undefined,
      shippingPriceFromApiStore: undefined,
      donationAmountSelected: 0,
      postnordStrike: false,
      sendAbroad: false,
      paymentRequest: null,
      paymentWalletType: null,
    };
  }

  async componentDidMount() {
    document.querySelector("html").setAttribute("style", "overflow: visible;");
    document.querySelector("#root").classList.add("checkout");
    let isNorwegianLanguage = false;
    if (getLanguageFromParams() === "no") {
      this.setState({ websiteLanguage: "no" });
    } else if (getLanguageFromCookie()) {
      if (getLanguageFromCookie() === "no") {
        isNorwegianLanguage = true;
        this.setState({ websiteLanguage: "no" });
      } else if (getLanguageFromCookie() === "sv") {
        this.setState({ websiteLanguage: "sv" });
      } else if (getLanguageFromCookie() === "da") {
        this.setState({ websiteLanguage: "da" });
      } else {
        this.setState({ websiteLanguage: "en" });
      }
    } else {
      if (getLanguageFromBrowser() === "no") {
        isNorwegianLanguage = true;
        this.setState({ websiteLanguage: "no" });
      } else if (getLanguageFromBrowser() === "sv") {
        this.setState({ websiteLanguage: "sv" });
      } else if (getLanguageFromBrowser() === "da") {
        this.setState({ websiteLanguage: "da" });
      } else {
        this.setState({ websiteLanguage: "en" });
      }
    }

    if (getUser()) {
      const userData = getUser();
      this.setState(
        {
          firstName: userData.firstName,
          lastName: userData.lastName,
          address: userData.address,
          postalCode: userData.postalCode,
          city: userData.city,
          confirmationEmailAddress: userData.confirmationEmailAddress,
          country: userData.country,
          state: userData.state,
          rememberMe: userData.rememberMe,
          cartItems: userData.cartItems,
          paymentMethod: userData.paymentMethod,
        },
        () => {
          if (window.location.search.includes("vipps")) {
            let params = new URL(window.location).searchParams;
            let orderId = params.get("vipps");
            let amount = params.get("amount");
            let order = params.get("order");
            this.captureVippsPayment(orderId, amount, order, true);
          }
        }
      );
    } else {
      this.setState(
        {
          firstName: "",
          lastName: "",
          address: "",
          postalCode: "",
          city: "",
          confirmationEmailAddress: "",
          country: setCountrySelectorDefaultValue(),
          state: "",
          rememberMe: false,
          cartItems: getCart(),
        },
        () => {
          if (window.location.search.includes("vipps")) {
            let params = new URL(window.location).searchParams;
            let orderId = params.get("vipps");
            let amount = params.get("amount");
            let order = params.get("order");
            this.captureVippsPayment(orderId, amount, order, false);
          }
        }
      );
      if (!isNorwegianLanguage) {
        const baseURL =
          "https://api.currencyapi.com/v3/latest?apikey=4fa02080-88d7-11ec-ab2b-357aa93837c6&base_currency=NOK";
        await fetch(`${baseURL}`, {
          type: "GET",
        })
          .then((data) => data.json())
          .then((res) => {
            if (res.error) {
              throw new Error(res.error);
            } else {
              this.setState({
                currencyRates: res.data,
                currenciesAvailable: true,
              });
            }
          })
          .catch((err) => {
            throw new Error(err);
          });
      }
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (!prevState.showOrderConfirmation && this.state.showOrderConfirmation) {
      document.querySelector("html").setAttribute("style", "overflow: hidden;");
    }
    if (!prevProps.stripe && this.props.stripe) {
      // Stripe Wallet Pay Payment Request
      this.createStripePaymentRequest();
    }
  };

  handleChange = (e) => {
    e.persist();
    let shippingMethod = this.state.shippingMethod;
    let deliveryTime = this.state.deliveryTime;
    let showShippingTag = false;
    let postalCodeChanged = false;
    let notNorwaySelected = false;
    if (
      e.target.name === "postalCode" &&
      this.state.shippingMethod === "store" &&
      !this.checkPostalCode()
    ) {
      shippingMethod = "mailbox";
      deliveryTime = undefined;
    } else if (e.target.name === "postalCode") {
      postalCodeChanged = true;
      if (e.target && e.target.classList.contains("validation-error")) {
        e.target.classList.remove("validation-error");
      }
    } else if (e.target.name === "country") {
      if (hasChangedShippingZones(this.state.country, e.target.value)) {
        showShippingTag = true;
      }
      if (isLongDistanceShipping(e.target.value)) {
        shippingMethod = "longDistanceStandardMail";
      } else {
        shippingMethod = "mailbox";
      }
      if (e.target.value !== "Norge") {
        notNorwaySelected = true;
      }
    }
    this.setState(
      {
        [e.target.name]: e.target.value,
        shippingMethod: shippingMethod,
        deliveryTime: deliveryTime,
        showShippingTag: showShippingTag,
        validPostalCodeFromBringApi: postalCodeChanged
          ? false
          : this.state.validPostalCodeFromBringApi,
        shippingPriceFromApiMailbox: postalCodeChanged
          ? undefined
          : this.state.shippingPriceFromApiMailbox,
        donationAmountSelected: notNorwaySelected
          ? 0
          : this.state.donationAmountSelected,
      },
      () => {
        if (showShippingTag) {
          setTimeout(() => this.setState({ showShippingTag: false }), 1000);
        }
      }
    );
  };

  handleFocus = (e) => {
    this.setState({ errorMessage: null });
    if (
      e.target &&
      e.target.classList &&
      e.target.classList.contains("validation-error")
    ) {
      e.target.classList.remove("validation-error");
    }
  };

  scrollToBottom = () => {
    document
      .querySelector(".section-checkout")
      .scrollIntoView({ behavior: "smooth", block: "end" });
  };

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  handleConfirmOrder = async (event) => {
    event.preventDefault();
    setUser(this.state);
    window.gtag("event", "gtag_report_conversion", {});
    if (containsRestrictedProducts(this.state.cartItems, this.state.country)) {
      this.setState({
        errorMessage:
          "⚠ " +
          translate(
            this.state.websiteLanguage,
            "CHECKOUT_FORM_VALIDATION_RESTRICTED_PRODUCTS"
          ),
        showRemoveRestrictedProductsButton: true,
      });
      this.scrollToBottom();
    } else if (this.isFormEpmty(this.state)) {
      this.setState({
        errorMessage: translate(
          this.state.websiteLanguage,
          "CHECKOUT_FORM_VALIDATION_MISSING"
        ),
        showRemoveRestrictedProductsButton: false,
      });
      this.scrollToBottom();
      return;
    } else if (!validEmailAddress(this.state.confirmationEmailAddress)) {
      this.setState({
        errorMessage: translate(
          this.state.websiteLanguage,
          "CHECKOUT_FORM_VALIDATION_EMAIL"
        ),
        showRemoveRestrictedProductsButton: false,
      });
      this.scrollToBottom();
      document
        .querySelector("#confirmationEmailAddress")
        .classList.add("validation-error");
      return;
    } else if (
      (this.state.shippingMethod === "longDistanceUps" ||
        !phoneNumberNotRequired.includes(this.state.country)) &&
      this.state.phone.trim() === ""
    ) {
      this.setState({
        errorMessage: translate(
          this.state.websiteLanguage,
          "CHECKOUT_FORM_VALIDATION_PHONE"
        ),
        showRemoveRestrictedProductsButton: false,
      });
      this.scrollToBottom();
      document.querySelector("#phone").classList.add("validation-error");
      return;
    } else if (
      this.state.country === "Norge" &&
      !this.state.validPostalCodeFromBringApi
    ) {
      this.setState({
        errorMessage: translate(
          this.state.websiteLanguage,
          "CHECKOUT_FORM_VALIDATION_POSTALCODE"
        ),
        showRemoveRestrictedProductsButton: false,
      });
      this.scrollToBottom();
      document.querySelector("#postalCode").classList.add("validation-error");
      return;
    } else if (
      this.state.shippingMethod === "store" &&
      !this.state.selectedPickUpPoint
    ) {
      this.setState({
        errorMessage: translate(
          this.state.websiteLanguage,
          "CHECKOUT_FORM_VALIDATION_NO_PICKUP_POINT"
        ),
        showRemoveRestrictedProductsButton: false,
      });
      this.scrollToBottom();
      document.querySelector("#postalCode").classList.add("validation-error");
      return;
    } else if (
      this.state.paymentMethod === "card" &&
      !this.state.cardDetailsComplete
    ) {
      this.setState({
        errorMessage: translate(
          this.state.websiteLanguage,
          "CHECKOUT_FORM_VALIDATION_CARD"
        ),
        showRemoveRestrictedProductsButton: false,
      });
      this.scrollToBottom();
      return;
    } else if (!this.state.agreementChecked) {
      this.setState({
        errorMessage: translate(
          this.state.websiteLanguage,
          "CHECKOUT_FORM_VALIDATION_AGREEMENT"
        ),
        showRemoveRestrictedProductsButton: false,
      });
      this.scrollToBottom();
      document
        .querySelector("#purchaseAgreement")
        .classList.add("validation-error");
    } else {
      this.setState(
        {
          errorMessage: null,
          modal: true,
          showRemoveRestrictedProductsButton: false,
        },
        () =>
          document
            .querySelector("html")
            .setAttribute("style", "overflow: hidden;")
      );
    }
  };

  validGiftCardFormat = (code) => {
    const giftcCardValid = code.match(/[s]\d{3}-[p]\d{3}-[b]\d{3}/i);
    return giftcCardValid;
  };

  renderDiscountText = () => {
    const discountText = `${translate(
      this.state.websiteLanguage,
      "CHECKOUT_DISCOUNT"
    )}: ${calculateDiscountCode(
      this.state.cartItems,
      this.state.discountType,
      this.state.selectedCurrency,
      this.state.currencyRates
    )}${renderAmountDecimals(this.state.selectedCurrency)}${
      this.state.websiteLanguage === "no"
        ? translate(this.state.websiteLanguage, "CURRENCY_NORWEGIAN")
        : this.state.selectedCurrency
    }`;
    return discountText;
  };

  renderDonationText = () => {
    const donationText = `${translate(
      this.state.websiteLanguage,
      "DONATION_LABEL"
    )}: ${this.state.donationAmountSelected},00 ${translate(
      this.state.websiteLanguage,
      "CURRENCY_NORWEGIAN"
    )}`;
    return donationText;
  };

  renderVatText = () => {
    let vatText = "";
    if (this.state.country === "Norge") {
      vatText = `<br/> ${translate(
        this.state.websiteLanguage,
        "CONFIRMATION_EMAIL_VAT"
      )}: ${vatTotalAmount(
        this.state.cartItems,
        this.state.country,
        this.state.discount,
        this.state.discountType,
        this.state.shippingMethod,
        this.state.selectedCurrency,
        this.state.currencyRates,
        this.state.shippingMethod === "mailbox"
          ? this.state.shippingPriceFromApiMailbox
          : this.state.shippingPriceFromApiStore
      )}${renderAmountDecimals(this.state.selectedCurrency)}${
        this.state.websiteLanguage === "no"
          ? translate(this.state.websiteLanguage, "CURRENCY_NORWEGIAN")
          : this.state.selectedCurrency
      }`;
    }
    return vatText;
  };

  cartItemsText = () => {
    let cartItemsString = "";
    this.state.cartItems.forEach((item) => {
      const normalOrder = item._id !== "customOrder";
      cartItemsString = `${cartItemsString}<p>${
        normalOrder ? item.quantity : ""
      }
      ${normalOrder ? " " : ""}
      ${
        normalOrder
          ? translate(this.state.websiteLanguage, "CONFIRMATION_EMAIL_UNITS")
          : ""
      }
      ${normalOrder ? " " : ""}
      ${item.name}: ${itemSumAmount(
        item.quantity,
        item.price,
        this.state.selectedCurrency,
        this.state.currencyRates
      )}${renderAmountDecimals(this.state.selectedCurrency)}${
        this.state.selectedCurrency === "NOK"
          ? translate(this.state.websiteLanguage, "CURRENCY_NORWEGIAN")
          : this.state.selectedCurrency
      }</p>`;
    });
    return cartItemsString;
  };

  shippingMethodTextMapping = (shippingMethod) => {
    switch (shippingMethod) {
      case "mailbox":
        return "rett i postkassen";
      case "store":
        return "hentested";
      case "longDistanceStandardMail":
        return "brev utland";
      case "longDistanceUps":
        return "UPS";
      default:
        return "rett i postkassen";
    }
  };

  cartContainsCustomOrder = () => {
    if (this.state.cartItems.some((item) => item._id === "customOrder")) {
      return true;
    }
    return false;
  };

  orderedItemsText = (vipps, shippingMethod) => {
    let orderedItemsString = "";
    this.state.cartItems.forEach((item) => {
      const normalOrder = item._id !== "customOrder";
      orderedItemsString = `${orderedItemsString}${
        normalOrder ? item.quantity : ""
      }${normalOrder ? " " : ""}${item.name}\n`;
    });
    if (this.state.donationAmountSelected > 0) {
      orderedItemsString = `${orderedItemsString}\nDonasjon: ${this.state.donationAmountSelected}\n`;
    }
    orderedItemsString = `${orderedItemsString}\n${
      vipps
        ? "Betalingsmetode: Vipps"
        : `Betalingsmetode: Stripe ${
            this.state.paymentWalletType ? this.state.paymentWalletType : ""
          }`
    }`;
    orderedItemsString = `${orderedItemsString}\nFraktmetode: ${this.shippingMethodTextMapping(
      shippingMethod
    )}`;
    return orderedItemsString;
  };

  confirmationEmailText = () => {
    let cartItemsString = this.cartItemsText();
    return `<div><p>${translate(
      this.state.websiteLanguage,
      "CONFIRMATION_EMAIL_HI"
    )} ${capitalizeName(this.state.firstName).trim()},</p>
    <p>${translate(
      this.state.websiteLanguage,
      "CONFIRMATION_EMAIL_THANKS"
    )}:</p>${cartItemsString}<p>${translate(
      this.state.websiteLanguage,
      "SHIPPING_AMOUNT"
    )} ${shippingAmount(
      this.state.cartItems,
      this.state.country,
      this.state.shippingMethod,
      this.state.selectedCurrency,
      this.state.currencyRates,
      this.state.shippingMethod === "mailbox"
        ? this.state.shippingPriceFromApiMailbox
        : this.state.shippingPriceFromApiStore,
      this.state.discount,
      this.state.discountType
    )}${renderAmountDecimals(this.state.selectedCurrency)}${
      this.state.websiteLanguage === "no"
        ? translate(this.state.websiteLanguage, "CURRENCY_NORWEGIAN")
        : this.state.selectedCurrency
    }${this.state.discount ? "<br/>" + this.renderDiscountText() : ""}${
      this.state.donationAmountSelected > 0
        ? "<br/>" + this.renderDonationText()
        : ""
    }<br/><strong>${translate(
      this.state.websiteLanguage,
      "TOTAL_AMOUNT"
    )}: ${totalAmount(
      this.state.cartItems,
      this.state.country,
      this.state.discount,
      this.state.discountType,
      this.state.shippingMethod,
      this.state.selectedCurrency,
      this.state.currencyRates,
      this.state.shippingMethod === "mailbox"
        ? this.state.shippingPriceFromApiMailbox
        : this.state.shippingPriceFromApiStore,
      this.state.donationAmountSelected
    )}${renderAmountDecimals(this.state.selectedCurrency)}${
      this.state.websiteLanguage === "no"
        ? translate(this.state.websiteLanguage, "CURRENCY_NORWEGIAN")
        : this.state.selectedCurrency
    }</strong>${this.renderVatText()}<p>${translate(
      this.state.websiteLanguage,
      "CONFIRMATION_EMAIL_INFO"
    )}${
      this.state.donationAmountSelected > 0
        ? translate(this.state.websiteLanguage, "DONATION_GREETING")
        : ""
    }</p><p><a href="${returnFormUrl(
      this.state.websiteLanguage
    )}" target="_blank" rel="noopener noreferrer">${translate(
      this.state.websiteLanguage,
      "CONFIRMATION_EMAIL_AGREEMENT"
    )}</a></p><p>${translate(
      this.state.websiteLanguage,
      "CONFIRMATION_EMAIL_SIGNATURE"
    )}</p></div>`;
  };

  shippingTrackingEmailBody = () => {
    return `<div><p>${translate(
      this.state.websiteLanguage,
      "CONFIRMATION_EMAIL_HI"
    )} ${capitalizeName(this.state.firstName).trim()},</p>
    <p><p>${translate(this.state.websiteLanguage, "TRACKING_EMAIL_BODY")}`;
  };

  shippingTrackingEmailSubject = () => {
    return translate(this.state.websiteLanguage, "TRACKING_EMAIL_SUBJECT");
  };

  shippingTrackingEmailSignature = () => {
    return `</p><p>${translate(
      this.state.websiteLanguage,
      "TRACKING_EMAIL_CUSTOMS_FEE_DISCLAIMER"
    )}</p><p>${translate(
      this.state.websiteLanguage,
      "TRACKING_EMAIL_THANKS"
    )}</p><p>${translate(
      this.state.websiteLanguage,
      "CONFIRMATION_EMAIL_SIGNATURE"
    )}</p></div>`;
  };

  initiateVippsPayment = async () => {
    this.setState(
      { orderProcessing: true, modal: true, redirectingToVipps: true },
      () => {
        setTimeout(() => {
          document
            .querySelector("html")
            .setAttribute("style", "overflow: visible;");
        }, 7500);
      }
    );
    const amount = totalAmount(
      this.state.cartItems,
      this.state.country,
      this.state.discount,
      this.state.discountType,
      this.state.shippingMethod,
      this.state.selectedCurrency,
      this.state.currencyRates,
      this.state.shippingMethod === "mailbox"
        ? this.state.shippingPriceFromApiMailbox
        : this.state.shippingPriceFromApiStore,
      this.state.donationAmountSelected
    );
    const orderId = `S${itemsInCart(
      this.state.cartItems,
      this.state.discount
    )}-P${Date.now()}-B${Math.floor(Math.random() * 10000)}`;
    const strapiOrderId = await this.sendToServer(
      amount,
      true,
      orderId,
      undefined
    );

    const address = this.state.address;
    const street = splitAddress(address).street;
    const number = splitAddress(address).number;
    const letter = splitAddress(address).letter;

    const packageSize = calculatePackageSize(this.state.cartItems);
    const packageWeight = calculatePackageWeight(this.state.cartItems);
    const description = createPackageDescription(this.state.cartItems);

    let phoneNumber = this.state.phone;
    let phoneNumberValid = false;
    if (phoneNumber.trim().length !== 0) {
      phoneNumber = phoneNumber
        .replace(/\s+/g, "")
        .replace(/[A-Za-z]+/, "")
        .replace("+47", "")
        .replace(/^(0047)/, "")
        .replace("+", "");
      phoneNumberValid = phoneNumber.length === 8;
    }

    await strapi
      .request("POST", "/initiateVippsPayment", {
        data: {
          amount: amount * 100,
          orderId: orderId,
          fallbackUrl: `${siteUrl}/checkout?vipps=${orderId}&amount=${amount}&order=${strapiOrderId}`,
          emailAddress: this.state.confirmationEmailAddress,
          emailSubject: translate(
            this.state.websiteLanguage,
            "CONFIRMATION_EMAIL_SUBJECT"
          ),
          emailText: `${translate(
            this.state.websiteLanguage,
            "CONFIRMATION_EMAIL_THANKS"
          )}!`,
          emailHtml: this.confirmationEmailText(),
          phone: phoneNumberValid ? phoneNumber : "",
          firstName: this.state.firstName.trim(),
          lastName: this.state.lastName.trim(),
          street,
          number,
          letter,
          postalCode: this.state.postalCode,
          city: this.state.city,
          country: this.state.country,
          shippingMethod: this.state.shippingMethod,
          packageSize,
          packageWeight,
          description,
          strapiOrderId,
          customerPickupPoint: this.state.selectedPickUpPoint
            ? this.state.selectedPickUpPoint
            : null,
          orderComment: this.state.comment,
        },
      })
      .catch((e) => {
        const errorMessage = `⚠ ${translate(
          this.state.websiteLanguage,
          "CHECKOUT_FORM_PAYMENT_ERROR"
        )}`;
        this.setState(
          {
            orderProcessing: false,
            modal: false,
            errorMessage,
          },
          () => this.scrollToBottom()
        );
      })
      .then((response) => {
        if (response) {
          window.location.href = response.url;
        }
      });
  };

  captureVippsPayment = async (
    orderId,
    amount,
    order,
    customerDataAvailable
  ) => {
    this.setState(
      { orderProcessing: true, modal: true, redirectingToVipps: false },
      () => {
        setTimeout(() => {
          document
            .querySelector("html")
            .setAttribute("style", "overflow: visible;");
        }, 7500);
      }
    );

    let paymentStatus = undefined;

    try {
      paymentStatus = await strapi.request("POST", "/checkVippsPaymentStatus", {
        data: {
          orderId: orderId,
        },
      });
    } catch (err) {
      console.log("could not get payment status:", err);
    }

    if (paymentStatus && paymentStatus === "AUTHORIZED") {
      try {
        await strapi.request("POST", "/captureVippsPayment2", {
          data: {
            orderId: orderId,
            orderAmount: amount * 100,
          },
        });
      } catch (err) {
        console.log("Could not capture payment:", err);
      }
      if (customerDataAvailable) {
        this.setState({
          orderProcessing: false,
          modal: false,
          showOrderConfirmation: true,
          errorMessage: null,
          summaryItems: this.state.cartItems,
        });
      } else {
        this.setState({
          showVippsConfirmationStatus: true,
          vippsError: false,
          modal: false,
          showOrderConfirmation: true,
          orderProcessing: false,
          errorMessage: null,
        });
      }
    } else {
      // Try to delete the payment if it has created state
      if (paymentStatus && paymentStatus === "CREATED") {
        try {
          await strapi.request("POST", "/cancelVippsPayment", {
            data: {
              orderId: orderId,
            },
          });
        } catch (err) {
          console.log("Could not cancel payment:", err);
        }
      }

      // Try to delete the temp. order if payment not authorized by user
      try {
        await strapi.request("POST", "/deleteOrder", {
          data: {
            strapiOrderId: order,
          },
        });
      } catch (err) {
        console.log("Could not delete order:", err);
      }

      if (customerDataAvailable) {
        const errorMessage = `⚠ ${translate(
          this.state.websiteLanguage,
          "CHECKOUT_FORM_PAYMENT_ERROR"
        )}`;
        this.setState(
          {
            orderProcessing: false,
            modal: false,
            errorMessage,
            showOrderConfirmation: false,
          },
          () => this.scrollToBottom()
        );
      } else {
        this.setState({
          showVippsConfirmationStatus: true,
          vippsError: true,
          orderProcessing: false,
          modal: false,
          showOrderConfirmation: true,
        });
      }
    }
    setTimeout(() => {
      window.history.replaceState({}, document.title, "/checkout");
    }, 100);
  };

  initiateGiftCardOrder = async (event) => {
    event.preventDefault();
    const redeemCode = this.state.giftcard;
    const amount = totalAmount(
      this.state.cartItems,
      this.state.country,
      this.state.discount,
      this.state.discountType,
      this.state.shippingMethod,
      this.state.selectedCurrency,
      this.state.currencyRates,
      this.state.shippingMethod === "mailbox"
        ? this.state.shippingPriceFromApiMailbox
        : this.state.shippingPriceFromApiStore,
      this.state.donationAmountSelected
    );
    this.setState({ orderProcessing: true });

    const response = await strapi.request("POST", "/redeemGiftCard", {
      data: {
        amount: amount,
        redeemCode: redeemCode,
      },
    });

    if (response.transactionId) {
      const orderId = `Gavekort: ${response.transactionId}`;
      this.sendToServer(amount, false, orderId, response.remainingCredit);
    } else {
      let errorMessage = "";
      if (response.wasAlreadyRedeemed) {
        errorMessage = `⚠ ${translate(
          this.state.websiteLanguage,
          "CHECKOUT_GIFTCARD_ERROR_ALREADY_USED"
        )}`;
      } else if (response.insufficientRemainingCredit) {
        errorMessage = `⚠ ${translate(
          this.state.websiteLanguage,
          "CHECKOUT_GIFTCARD_ERROR_INSUFFICIENT"
        )}`;
      } else {
        errorMessage = `⚠ ${translate(
          this.state.websiteLanguage,
          "CHECKOUT_GIFTCARD_NOT_VALID"
        )}`;
      }
      this.setState(
        {
          orderProcessing: false,
          modal: false,
          errorMessage,
        },
        () => {
          document
            .querySelector("html")
            .setAttribute("style", "overflow: visible;");
        }
      );
    }
  };

  createStripePaymentRequest = async (event) => {
    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    pr = await stripe.paymentRequest({
      country: "NO",
      currency: "nok",
      total: {
        label: "3charlie.no",
        amount:
          totalAmount(
            this.state.cartItems,
            this.state.country,
            this.state.discount,
            this.state.discountType,
            this.state.shippingMethod,
            "NOK",
            false,
            this.state.shippingMethod === "mailbox"
              ? this.state.shippingPriceFromApiMailbox
              : this.state.shippingPriceFromApiStore,
            this.state.donationAmountSelected
          ) * 100,
      },
      requestPayerName: false,
      requestPayerEmail: false,
    });

    pr.canMakePayment().then((result) => {
      if (result) {
        let walletType = null;
        if (result.applePay) {
          walletType = "applePay";
        } else if (result.googlePay) {
          walletType = "googlePay";
        }
        this.setState({ paymentRequest: pr, paymentWalletType: walletType });
      }
    });

    pr.on("paymentmethod", async (e) => {
      this.handleSubmitOrderWallet(e);
    });
  };

  handleSubmitOrder = async (event) => {
    event.preventDefault();
    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }
    const { firstName, lastName, confirmationEmailAddress } = this.state;
    this.setState({ orderProcessing: true });
    let fullName = firstName.trim() + " " + lastName.trim();
    fullName = capitalizeName(fullName);

    const amount = totalAmount(
      this.state.cartItems,
      this.state.country,
      this.state.discount,
      this.state.discountType,
      this.state.shippingMethod,
      "NOK",
      false,
      this.state.shippingMethod === "mailbox"
        ? this.state.shippingPriceFromApiMailbox
        : this.state.shippingPriceFromApiStore,
      this.state.donationAmountSelected
    );

    const response = await strapi.request("POST", "/createIntent", {
      data: {
        amount: amount * 100,
        currency: "nok",
        description: `${itemsInCart(
          this.state.cartItems
        )} units to ${fullName}`,
      },
    });

    const result = await stripe.confirmCardPayment(response.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: fullName,
          email: confirmationEmailAddress,
        },
      },
    });

    if (result.error) {
      const errorMessage = `⚠ ${result.error.message}`;
      this.setState({
        orderProcessing: false,
        modal: false,
        errorMessage,
        clientSecret: "",
      });
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "requires_action") {
        console.log("handle 3d secure");
        const { error } = await stripe.confirmCardPayment(
          response.client_secret
        );
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          const errorMessage = `⚠ ${translate(
            this.state.websiteLanguage,
            "ORDER_VIPPS_ERROR"
          )}`;
          this.setState({
            orderProcessing: false,
            modal: false,
            errorMessage,
            clientSecret: "",
          });
          return;
        } else {
          // The payment succeeded
          const strapiOrderId = await this.sendToServer(
            amount,
            false,
            false,
            undefined
          );
          if (
            this.state.country === "Norge" &&
            this.state.shippingMethod === "mailbox" &&
            amount !== 3
          ) {
            // Bring shipping booking
            this.validateAddressAndBookShipping(
              this.state.address,
              this.state.postalCode,
              this.state.city,
              this.state.firstName.trim(),
              this.state.lastName.trim(),
              this.state.confirmationEmailAddress,
              this.state.phone,
              this.state.cartItems,
              strapiOrderId
            );
          } else if (
            this.state.country === "Norge" &&
            this.state.shippingMethod === "store" &&
            amount !== 3
          ) {
            // Postnord shipping booking
            this.validateAndBookShippingPostnord(
              this.state.selectedPickUpPoint,
              this.state.address,
              this.state.postalCode,
              this.state.city,
              this.state.firstName.trim(),
              this.state.lastName.trim(),
              this.state.confirmationEmailAddress,
              this.state.phone,
              this.state.cartItems,
              strapiOrderId
            );
          } else if (
            supportedCountriesTrackedShippingBring.includes(
              this.state.country
            ) &&
            this.state.shippingMethod === "mailbox" &&
            amount !== 3
          ) {
            // Bring shipping international booking
            this.validateAddressAndBookShippingBringIntl(
              this.state.address,
              this.state.postalCode,
              this.state.city,
              this.state.firstName.trim(),
              this.state.lastName.trim(),
              this.state.confirmationEmailAddress,
              this.state.phone,
              this.state.country,
              this.state.cartItems,
              strapiOrderId
            );
          }
        }
      }
      if (result.paymentIntent.status === "succeeded") {
        const strapiOrderId = await this.sendToServer(
          amount,
          false,
          false,
          undefined
        );
        if (
          this.state.country === "Norge" &&
          this.state.shippingMethod === "mailbox" &&
          amount !== 3
        ) {
          // Bring shipping booking
          this.validateAddressAndBookShipping(
            this.state.address,
            this.state.postalCode,
            this.state.city,
            this.state.firstName.trim(),
            this.state.lastName.trim(),
            this.state.confirmationEmailAddress,
            this.state.phone,
            this.state.cartItems,
            strapiOrderId
          );
        } else if (
          this.state.country === "Norge" &&
          this.state.shippingMethod === "store" &&
          amount !== 3
        ) {
          // Postnord shipping booking
          this.validateAndBookShippingPostnord(
            this.state.selectedPickUpPoint,
            this.state.address,
            this.state.postalCode,
            this.state.city,
            this.state.firstName.trim(),
            this.state.lastName.trim(),
            this.state.confirmationEmailAddress,
            this.state.phone,
            this.state.cartItems,
            strapiOrderId
          );
        } else if (
          supportedCountriesTrackedShippingBring.includes(this.state.country) &&
          this.state.shippingMethod === "mailbox" &&
          amount !== 3
        ) {
          // Bring shipping international booking
          this.validateAddressAndBookShippingBringIntl(
            this.state.address,
            this.state.postalCode,
            this.state.city,
            this.state.firstName.trim(),
            this.state.lastName.trim(),
            this.state.confirmationEmailAddress,
            this.state.phone,
            this.state.country,
            this.state.cartItems,
            strapiOrderId
          );
        }
      }
    }
  };

  updatePaymentRequestInstance = async (e) => {
    pr.update({
      total: {
        label: "3charlie.no",
        amount:
          totalAmount(
            this.state.cartItems,
            this.state.country,
            this.state.discount,
            this.state.discountType,
            this.state.shippingMethod,
            "NOK",
            false,
            this.state.shippingMethod === "mailbox"
              ? this.state.shippingPriceFromApiMailbox
              : this.state.shippingPriceFromApiStore,
            this.state.donationAmountSelected
          ) * 100,
      },
    });
  };

  handleSubmitOrderWallet = async (e) => {
    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }
    const { firstName, lastName } = this.state;
    this.setState({ orderProcessing: true });
    let fullName = firstName.trim() + " " + lastName.trim();
    fullName = capitalizeName(fullName);

    const amount = totalAmount(
      this.state.cartItems,
      this.state.country,
      this.state.discount,
      this.state.discountType,
      this.state.shippingMethod,
      "NOK",
      false,
      this.state.shippingMethod === "mailbox"
        ? this.state.shippingPriceFromApiMailbox
        : this.state.shippingPriceFromApiStore,
      this.state.donationAmountSelected
    );

    const response = await strapi.request("POST", "/createIntent", {
      data: {
        amount: amount * 100,
        currency: "nok",
        description: `${itemsInCart(
          this.state.cartItems
        )} units to ${fullName} (${this.state.paymentWalletType})`,
      },
    });

    const clientSecret = response.client_secret;

    const { paymentIntent, error: confirmError } =
      await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: e.paymentMethod.id },
        { handleActions: false }
      );

    if (confirmError) {
      e.complete("fail");
      const errorMessage = `⚠ ${translate(
        this.state.websiteLanguage,
        "ORDER_VIPPS_ERROR"
      )}`;
      this.setState({
        orderProcessing: false,
        modal: false,
        errorMessage,
        clientSecret: "",
      });
      return;
    } else {
      e.complete("success");
      if (paymentIntent.status === "requires_action") {
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          const errorMessage = `⚠ ${translate(
            this.state.websiteLanguage,
            "ORDER_VIPPS_ERROR"
          )}`;
          this.setState({
            orderProcessing: false,
            modal: false,
            errorMessage,
            clientSecret: "",
          });
          return;
        } else {
          // The payment succeeded
          const strapiOrderId = await this.sendToServer(
            amount,
            false,
            false,
            undefined
          );
          if (
            this.state.country === "Norge" &&
            this.state.shippingMethod === "mailbox" &&
            amount !== 3
          ) {
            // Bring shipping booking
            this.validateAddressAndBookShipping(
              this.state.address,
              this.state.postalCode,
              this.state.city,
              this.state.firstName.trim(),
              this.state.lastName.trim(),
              this.state.confirmationEmailAddress,
              this.state.phone,
              this.state.cartItems,
              strapiOrderId
            );
          } else if (
            this.state.country === "Norge" &&
            this.state.shippingMethod === "store" &&
            amount !== 3
          ) {
            // Postnord shipping booking
            this.validateAndBookShippingPostnord(
              this.state.selectedPickUpPoint,
              this.state.address,
              this.state.postalCode,
              this.state.city,
              this.state.firstName.trim(),
              this.state.lastName.trim(),
              this.state.confirmationEmailAddress,
              this.state.phone,
              this.state.cartItems,
              strapiOrderId
            );
          } else if (
            supportedCountriesTrackedShippingBring.includes(
              this.state.country
            ) &&
            this.state.shippingMethod === "mailbox" &&
            amount !== 3
          ) {
            // Bring shipping international booking
            this.validateAddressAndBookShippingBringIntl(
              this.state.address,
              this.state.postalCode,
              this.state.city,
              this.state.firstName.trim(),
              this.state.lastName.trim(),
              this.state.confirmationEmailAddress,
              this.state.phone,
              this.state.country,
              this.state.cartItems,
              strapiOrderId
            );
          }
        }
      } else {
        // The payment succeeded
        const strapiOrderId = await this.sendToServer(
          amount,
          false,
          false,
          undefined
        );
        if (
          this.state.country === "Norge" &&
          this.state.shippingMethod === "mailbox" &&
          amount !== 3
        ) {
          // Bring shipping booking
          this.validateAddressAndBookShipping(
            this.state.address,
            this.state.postalCode,
            this.state.city,
            this.state.firstName.trim(),
            this.state.lastName.trim(),
            this.state.confirmationEmailAddress,
            this.state.phone,
            this.state.cartItems,
            strapiOrderId
          );
        } else if (
          this.state.country === "Norge" &&
          this.state.shippingMethod === "store" &&
          amount !== 3
        ) {
          // Postnord shipping booking
          this.validateAndBookShippingPostnord(
            this.state.selectedPickUpPoint,
            this.state.address,
            this.state.postalCode,
            this.state.city,
            this.state.firstName.trim(),
            this.state.lastName.trim(),
            this.state.confirmationEmailAddress,
            this.state.phone,
            this.state.cartItems,
            strapiOrderId
          );
        } else if (
          supportedCountriesTrackedShippingBring.includes(this.state.country) &&
          this.state.shippingMethod === "mailbox" &&
          amount !== 3
        ) {
          // Bring shipping international booking
          this.validateAddressAndBookShippingBringIntl(
            this.state.address,
            this.state.postalCode,
            this.state.city,
            this.state.firstName.trim(),
            this.state.lastName.trim(),
            this.state.confirmationEmailAddress,
            this.state.phone,
            this.state.country,
            this.state.cartItems,
            strapiOrderId
          );
        }
      }
    }
  };

  discountPriceInvoiceCalculation = (product, vat) => {
    if (this.state.discount && this.state.discountType === "BLACKFRIDAY") {
      if (this.state.discountType === "BLACKFRIDAY") {
        if (
          product._id === "66d609418feda60015d5e27e" ||
          product._id === "64e510bd0745be001453f48a" ||
          product._id === "6427a68e3ae7e1001417e938" ||
          product._id === "67110f24a8dc980015f09f36" ||
          product._id === "65429ce2a6340f0014698341" ||
          product._id === "6427ace83ae7e1001417e94d" ||
          product._id === "66ae875aa341210015813903"
        ) {
          return vat
            ? product.price * 100 * 0.8 - BLACK_FRIDAY_DISCOUNT
            : product.price * 100 - BLACK_FRIDAY_DISCOUNT;
        }
      }
    } else {
      return vat ? product.price * 100 * 0.8 : product.price * 100;
    }
  };

  createInvoiceProductLines = (
    products,
    shippingCost,
    vat,
    donation,
    discountType
  ) => {
    let productLines = [];
    products.forEach((product) => {
      productLines.push({
        description: product.name,
        unitPrice: vat ? product.price * 100 * 0.8 : product.price * 100,
        vatType: vat ? "HIGH" : "EXEMPT_IMPORT_EXPORT",
        quantity: product.quantity,
        discount:
          discountType === OPRA_DISCOUNT_CODE
            ? OPRA_DISCOUNT_PERCENTAGE * 100
            : 0,
        incomeAccount: vat ? 3000 : 3100,
      });
    });
    if (donation > 0) {
      productLines.push({
        productId: 4124815080,
        unitPrice: donation * 100,
        vatType: "EXEMPT",
        quantity: 1,
      });
    }
    if (shippingCost > 0) {
      productLines.push({
        productId: vat ? 2416354591 : 5904121103,
        unitPrice: vat ? shippingCost * 100 * 0.8 : shippingCost * 100,
        vatType: vat ? "HIGH" : "EXEMPT_IMPORT_EXPORT",
        quantity: 1,
      });
    }
    return productLines;
  };

  createCustomsDeclarationLines = (products) => {
    let declarationLines = [];
    products.forEach((product) => {
      declarationLines.push({
        countryOfOrigin: product.exportShippingInfo.originCountry,
        currency: "NOK",
        customsArticleNumber: product.exportShippingInfo.tariffCode,
        goodsDescription: product.exportShippingInfo.description,
        itemNetWeightInKg: product.weightInKilos,
        quantity: product.quantity,
        tarriffLineAmount: product.price,
      });
    });
    return declarationLines;
  };

  getShippingPriceBring = async (postalCode, orderedItems) => {
    this.setState({
      loadingPriceFromApiMailbox: true,
    });
    const packageSize = calculatePackageSize(orderedItems);
    const packageWeight = calculatePackageWeight(orderedItems);
    await strapi
      .request("POST", "/getShippingPriceBring", {
        data: {
          postalCode,
          packageSize,
          packageWeight,
        },
      })
      .catch((e) => {
        this.setState({
          loadingPriceFromApiMailbox: false,
          shippingPriceFromApiMailbox: 0,
        });
      })
      .then((response) => {
        if (response) {
          this.setState({
            loadingPriceFromApiMailbox: false,
            shippingPriceFromApiMailbox: response.shippingPrice + 10,
            expectedDeliveryDaysFromApiMailbox: response.expectedDelivery,
            validPostalCodeFromBringApi: response.validPostalCode,
          });
        } else {
          this.setState({
            loadingPriceFromApiMailbox: false,
            validPostalCodeFromBringApi: true,
          });
        }
      });
  };

  validateAddressAndBookShipping = async (
    address,
    postalCode,
    city,
    firstName,
    lastName,
    email,
    phone,
    orderedItems,
    strapiOrderId
  ) => {
    const street = splitAddress(address).street;
    const number = splitAddress(address).number;
    const letter = splitAddress(address).letter;
    let fullName = `${firstName.trim()} ${lastName.trim()}`;
    fullName = capitalizeName(fullName);

    const packageSize = calculatePackageSize(orderedItems);
    const packageWeight = calculatePackageWeight(orderedItems);
    const description = createPackageDescription(orderedItems);

    try {
      await strapi.request("POST", "/validateAddressBringBooking", {
        data: {
          name: fullName,
          street: street,
          number: number,
          letter: letter ? letter : "",
          postalCode: postalCode,
          city: city,
          email: email,
          phone: phone,
          size: packageSize,
          weight: packageWeight,
          description,
          strapiOrderId,
        },
      });
    } catch (err) {
      console.log("could not validate address and book bring shipping:", err);
    }
  };

  validateAddressAndBookShippingBringIntl = async (
    address,
    postalCode,
    city,
    firstName,
    lastName,
    email,
    phone,
    country,
    orderedItems,
    strapiOrderId
  ) => {
    const street = splitAddress(address).street;
    const number = splitAddress(address).number;
    const letter = splitAddress(address).letter;
    let fullName = `${firstName.trim()} ${lastName.trim()}`;
    fullName = capitalizeName(fullName);

    /*
    Replace special characters in street name and city
    const streetReplaced = street
      .toLowerCase()
      .replace(/[äéèöøæå]/g, (m) => specialAddressCharacters[m]);
    const cityReplaced = city
      .toLowerCase()
      .replace(/[äéèöøæå]/g, (m) => specialAddressCharacters[m]);
      */

    const packageSize = calculatePackageSize(orderedItems);
    const packageWeight = calculatePackageWeight(orderedItems);
    const description = createPackageDescription(orderedItems);
    const countryCode = countryCodeMapper(country);
    const phoneNumber = parsePhoneNumber(phone, countryCode);

    const ediCustomsDeclaration =
      this.createCustomsDeclarationLines(orderedItems);

    try {
      await strapi.request("POST", "/validateAddressBringBookingIntl", {
        data: {
          name: fullName,
          street,
          number,
          letter: letter ? letter : "",
          postalCode: postalCode,
          city,
          country,
          countryCode,
          email: email,
          phone: phoneNumber ? phoneNumber.number : phone,
          size: packageSize,
          weight: packageWeight,
          description,
          strapiOrderId,
          ediCustomsDeclaration,
          trackingEmailSubject: this.shippingTrackingEmailSubject(),
          trackingEmailBody: this.shippingTrackingEmailBody(),
          trackingEmailSignature: this.shippingTrackingEmailSignature(),
        },
      });
    } catch (err) {
      console.log("could not validate address and book bring shipping:", err);
    }
  };

  validateAndBookShippingPostnord = async (
    selectedPickUpPoint,
    address,
    postalCode,
    city,
    firstName,
    lastName,
    email,
    phone,
    orderedItems,
    strapiOrderId
  ) => {
    const packageSize = calculatePackageSize(orderedItems);
    const packageWeight = calculatePackageWeight(orderedItems);

    let fullName = `${firstName.trim()} ${lastName.trim()}`;
    fullName = capitalizeName(fullName);

    try {
      await strapi.request("POST", "/makePostnordBooking", {
        data: {
          customerPikcupPoint: selectedPickUpPoint,
          name: fullName,
          address: address,
          postalCode: postalCode,
          city: city,
          email: email,
          phone: phone,
          size: packageSize,
          weight: packageWeight,
          strapiOrderId,
        },
      });
    } catch (err) {
      console.log("could not make Postnord booking:", err);
    }
  };

  sendToServer = async (
    paidAmount,
    vippsPreOrder,
    orderId,
    remainingCredits
  ) => {
    const {
      cartItems,
      firstName,
      lastName,
      address,
      postalCode,
      city,
      confirmationEmailAddress,
      country,
      phone,
      comment,
      state,
      shippingMethod,
      selectedPickUpPoint,
      shippingPriceFromApiMailbox,
      shippingPriceFromApiStore,
      donationAmountSelected,
      discount,
      discountType,
    } = this.state;
    const amount = paidAmount;
    const orderNumber = orderId ? orderId : "";
    const browser = detect();
    let fullName = firstName.trim() + " " + lastName.trim();
    fullName = capitalizeName(fullName);
    const email = confirmationEmailAddress.toLowerCase();
    const phoneNumber = phone.replace(/[- .]/g, "");
    let order = {};
    try {
      order = await strapi
        .createEntry("orders", {
          name: fullName,
          address,
          postalCode,
          city,
          products: cartItems,
          amount,
          email: email,
          country,
          phone: phoneNumber,
          state,
          comment,
          orderTime: `${new Date(Date.now())}`,
          orderedItems: this.orderedItemsText(vippsPreOrder, shippingMethod),
          shippingMethod,
          selectedPickUpPoint:
            selectedPickUpPoint && selectedPickUpPoint.name
              ? selectedPickUpPoint.name
              : null,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          console.log("error creating order", err);
        });
      try {
        await strapi.request("POST", "/email", {
          data: {
            to: email,
            subject: translate(
              this.state.websiteLanguage,
              "CONFIRMATION_EMAIL_SUBJECT"
            ),
            text: `${translate(
              this.state.websiteLanguage,
              "CONFIRMATION_EMAIL_THANKS"
            )}!`,
            html: this.confirmationEmailText(),
            vippsPreOrder: vippsPreOrder,
            orderId: orderNumber,
            browser: browser,
            comment: comment,
          },
        });
      } catch (err) {
        console.log("could not send email:", err);
      }
      if (isProduction) {
        const shippingCost = shippingAmount(
          cartItems,
          country,
          shippingMethod,
          "NOK",
          null,
          shippingMethod === "mailbox"
            ? shippingPriceFromApiMailbox
            : shippingPriceFromApiStore,
          discount,
          discountType
        );
        const vat = country === "Norge";
        try {
          await strapi.request("POST", "/createDraftInvoice", {
            data: {
              productLines: this.createInvoiceProductLines(
                cartItems,
                shippingCost,
                vat,
                donationAmountSelected,
                discountType
              ),
              vipps: vippsPreOrder,
              name: fullName,
              address,
              postalCode,
              city,
              email,
              phoneNumber,
              country: countryMapper(country),
              state,
              comment: "",
            },
          });
        } catch (err) {
          console.log("could not create draft invoice:", err);
        }
        // Try to update stock spreadsheets after order is created
        try {
          await strapi.request("POST", "/updateStockSpreadSheet", {
            data: { orderedItems: cartItems },
          });
        } catch (err) {
          console.log("could not update stock spreadsheet", err);
        }
      }
      if (!vippsPreOrder) {
        this.setState({
          orderProcessing: false,
          modal: false,
          showOrderConfirmation: true,
          errorMessage: null,
          summaryItems: cartItems,
          remainingGiftcardCredits: remainingCredits,
        });
      }
    } catch (err) {
      console.log("could not complete order registration:", err);
      this.setState({
        orderProcessing: false,
        modal: false,
        showOrderError: true,
      });
      document
        .querySelector("html")
        .setAttribute("style", "overflow: visible;");
      setTimeout(() => {
        this.setState({ showOrderError: false });
      }, 15000);
    }
    return order;
  };

  goToFrontPage() {
    document.querySelector("html").setAttribute("style", "overflow: visible;");
    this.setState({ remainingGiftcardCredits: "" });
    clearCart();
    clearUser();
    this.setState({ showOrderConfirmation: false, errorMessage: null }, () =>
      this.props.history.push("/")
    );
  }

  isFormEpmty = (obj) => {
    if (obj.country === "USA" || obj.country === "Canada") {
      return !obj.state;
    } else {
      return (
        !obj.firstName ||
        !obj.lastName ||
        !obj.address ||
        !obj.postalCode ||
        !obj.city ||
        !obj.confirmationEmailAddress ||
        !obj.country
      );
    }
  };

  closeModal = () => {
    this.setState({ modal: false }, () =>
      document.querySelector("html").setAttribute("style", "overflow: visible;")
    );
  };

  setComplete = (complete) => {
    this.setState({ cardDetailsComplete: complete });
  };

  toggleAgreementVisibility = () => {
    if (this.state.showAgreement) {
      document
        .querySelector("html")
        .setAttribute("style", "overflow: visible;");
    } else {
      document.querySelector("html").setAttribute("style", "overflow: hidden;");
    }
    this.setState({ showAgreement: !this.state.showAgreement });
  };

  checkDiscountCode = async () => {
    this.setState({ checkDiscountCode: true });
    await strapi
      .request("POST", "/checkDiscountCode", {
        data: {
          discountCode: this.state.discountCode,
        },
      })
      .catch((e) => {
        console.log(e);
        this.setState({ checkDiscountCode: false });
      })
      .then((response) => {
        if (response.discountValid && !this.cartContainsCustomOrder()) {
          this.setState({
            discount: true,
            discountType: response.discountType,
            discountMessage: translate(
              this.state.websiteLanguage,
              "CHECKOUT_FORM_DISCOUNT_CODE_VALID"
            ),
          });
        } else if (response.discountValid && this.cartContainsCustomOrder()) {
          this.setState({
            discount: false,
            discountMessage: translate(
              this.state.websiteLanguage,
              "CHECKOUT_FORM_DISCOUNT_CODE_INVALID_CUSTOM_ORDER"
            ),
          });
        } else {
          this.setState({
            discount: false,
            discountMessage: translate(
              this.state.websiteLanguage,
              "CHECKOUT_FORM_DISCOUNT_CODE_INVALID"
            ),
          });
        }
        this.setState({ checkDiscountCode: false });
      });
  };

  showDiscountCode = () => {
    this.setState({
      showDiscountInput: !this.state.showDiscountInput,
      discountMessage: undefined,
    });
  };

  checkPostalCode = (postalCode) => {
    const postalCodeValid = /^\d{4}$/.test(postalCode);
    return postalCodeValid;
  };

  postalCodeFieldOnBlur = () => {
    if (
      this.state.country === "Norge" &&
      this.checkPostalCode(this.state.postalCode)
    ) {
      this.getShippingPriceBring(this.state.postalCode, this.state.cartItems);
      this.getPostnordPoints();
    }
    if (cartContainsPickupPointOnlyItem(this.state.cartItems)) {
      this.setState({ shippingMethod: "store" });
    }
  };

  getPostnordPoints = async () => {
    this.setState({
      loadingPickUpPoints: true,
      pickUpPoints: [],
      selectedPickUpPoint: undefined,
    });
    const packageSize = calculatePackageSize(this.state.cartItems);
    const packageWeight = calculatePackageWeight(this.state.cartItems);
    await strapi
      .request("POST", "/getPostnordPoints", {
        data: {
          consigneePostalCode: this.state.postalCode,
          packageSize,
          packageWeight,
        },
      })
      .catch((e) => {
        this.setState({ loadingPickUpPoints: false });
      })
      .then((response) => {
        const collectAtStore =
          response && response.products.filter((e) => e.productCode === "Z");
        if (
          collectAtStore &&
          collectAtStore.length > 0 &&
          collectAtStore[0].serviceLocations.length > 0
        ) {
          this.setState({
            pickUpPoints: collectAtStore[0].serviceLocations,
            deliveryTime: collectAtStore[0].expectedDelivery.workingDays,
            loadingPickUpPoints: false,
            selectedPickUpPoint: collectAtStore[0].serviceLocations[0],
            shippingPriceFromApiStore:
              Math.ceil(
                (collectAtStore[0].price.priceBreakdown.netAmount.value *
                  1.25) /
                  10
              ) *
                10 +
              20,
          });
        } else {
          this.setState({
            loadingPickUpPoints: false,
            deliveryTime: undefined,
            pickUpPoints: [],
            selectedPickUpPoint: undefined,
            shippingPriceFromApiStore: 0,
          });
        }
      });
  };

  onSelectShppingToStore = () => {
    if (this.state.postalCode !== this.state.cachedPickUpPoints) {
      this.setState({ cachedPickUpPoints: this.state.postalCode });
    }
    this.setState({ shippingMethod: "store" });
  };

  setSelectedPickUpPoint = (pickupPoint) => {
    this.setState({ selectedPickUpPoint: pickupPoint });
  };

  renderPickupLocations = () => {
    let pickUpPoints = undefined;
    if (
      !this.state.loadingPickUpPoints &&
      this.state.shippingMethod === "store" &&
      this.state.pickUpPoints.length > 0
    ) {
      pickUpPoints = this.state.pickUpPoints.map((item, i) => (
        <div
          className={`pay-method-btn indented ${
            this.state.selectedPickUpPoint.name === item.name
              ? "enabled"
              : "disabled"
          }`}
          key={item.id}
          onClick={() => this.setSelectedPickUpPoint(item)}
        >
          {item.name}
        </div>
      ));
    } else if (
      !this.state.loadingPickUpPoints &
        (this.state.shippingMethod === "store") &&
      this.state.pickUpPoints.length === 0
    ) {
      pickUpPoints = (
        <p id="pickupPoint" className="pickup-point-nothing-found">
          {translate(
            this.state.websiteLanguage,
            "CHECKOUT_FORM_VALIDATION_ERROR_PICKUP_POINT"
          )}
        </p>
      );
    }
    return pickUpPoints;
  };

  setPostnordDeliveryTime = () => {
    const deliveryDays = this.state.deliveryTime;
    let deliveryText = "2-4";
    if (deliveryDays) {
      deliveryText = `${deliveryDays + 2}-${deliveryDays + 3}`;
    }
    return deliveryText;
  };

  setBringDeliveryTime = () => {
    const deliveryDays = this.state.expectedDeliveryDaysFromApiMailbox;
    let deliveryText = "5-6";
    if (deliveryDays) {
      deliveryText = `${deliveryDays}-${deliveryDays + 1}`;
    }
    return deliveryText;
  };

  setLongDistanceDeliveryTime = (shippingMethod) => {
    let deliveryText = "12-16";
    if (shippingMethod === "longDistanceUps") {
      deliveryText = "5-8";
    }
    return deliveryText;
  };

  removeRestrictedProducts = (items) => {
    const updatedCart = removeRestrictedItemsFromCart(items);
    this.setState({
      cartItems: updatedCart,
      errorMessage: undefined,
      showRemoveRestrictedProductsButton: false,
    });
    setCart(updatedCart);
    this.scrollToTop();
  };

  renderTotalPaymentAmount = (
    cartItems,
    country,
    discount,
    discountType,
    shippingMethod,
    selectedCurrency,
    currencyRates,
    shippingPriceFromApi,
    loadingPriceFromApiMailbox,
    donationAmountSelected
  ) => {
    if (
      country === "Norge" &&
      shippingPriceFromApi === undefined &&
      !loadingPriceFromApiMailbox
    ) {
      return (
        <div>
          {calculateAmount(
            cartItems,
            discount,
            discountType,
            selectedCurrency,
            currencyRates
          ) + this.state.donationAmountSelected}
          {renderAmountDecimals(selectedCurrency)}
          {this.state.websiteLanguage === "no"
            ? translate(this.state.websiteLanguage, "CURRENCY_NORWEGIAN")
            : this.state.selectedCurrency}
        </div>
      );
    } else if (loadingPriceFromApiMailbox) {
      return (
        <StageSpinner
          size={30}
          loading={loadingPriceFromApiMailbox}
          color={"#bcf135"}
        />
      );
    } else {
      return (
        <div>
          {totalAmount(
            cartItems,
            country,
            discount,
            discountType,
            shippingMethod,
            selectedCurrency,
            currencyRates,
            shippingPriceFromApi,
            donationAmountSelected
          )}
          {renderAmountDecimals(selectedCurrency)}
          {this.state.websiteLanguage === "no"
            ? translate(this.state.websiteLanguage, "CURRENCY_NORWEGIAN")
            : this.state.selectedCurrency}
        </div>
      );
    }
  };

  renderMailboxShippingAmount = (
    cartItems,
    country,
    shippingMethod,
    selectedCurrency,
    currencyRates,
    shippingPriceFromApiMailbox,
    loadingPriceFromApiMailbox
  ) => {
    const shippingCost = shippingAmount(
      cartItems,
      country,
      shippingMethod,
      selectedCurrency,
      currencyRates,
      shippingPriceFromApiMailbox,
      this.state.discount,
      this.state.discountType
    );
    if (shippingCost === 0) {
      return (
        <span>{translate(this.state.websiteLanguage, "SHIPPING_FREE")}</span>
      );
    } else if (!this.state.validPostalCodeFromBringApi) {
      return (
        <span>
          {translate(
            this.state.websiteLanguage,
            "CHECKOUT_SHIPPING_PLACEHOLDER_MESSAGE"
          )}
        </span>
      );
    } else if (
      country === "Norge" &&
      shippingPriceFromApiMailbox === undefined &&
      !loadingPriceFromApiMailbox
    ) {
      return (
        <span>
          {translate(
            this.state.websiteLanguage,
            "CHECKOUT_SHIPPING_PLACEHOLDER_MESSAGE"
          )}
        </span>
      );
    } else if (loadingPriceFromApiMailbox) {
      return (
        <StageSpinner
          size={20}
          loading={loadingPriceFromApiMailbox}
          color={"#bcf135"}
        />
      );
    } else {
      return (
        <span>
          {shippingCost}
          {renderAmountDecimals(selectedCurrency)}
          {this.state.websiteLanguage === "no"
            ? translate(this.state.websiteLanguage, "CURRENCY_NORWEGIAN")
            : this.state.selectedCurrency}
        </span>
      );
    }
  };

  renderStoreShippingAmount = (
    cartItems,
    country,
    shippingMethod,
    selectedCurrency,
    currencyRates,
    shippingPriceFromApi
  ) => {
    const shippingCost = shippingAmount(
      cartItems,
      country,
      shippingMethod,
      selectedCurrency,
      currencyRates,
      shippingPriceFromApi,
      this.state.discount,
      this.state.discountType
    );
    if (!this.state.validPostalCodeFromBringApi) {
      return (
        <span>
          {translate(
            this.state.websiteLanguage,
            "CHECKOUT_SHIPPING_PLACEHOLDER_MESSAGE"
          )}
        </span>
      );
    } else if (
      country === "Norge" &&
      shippingPriceFromApi === undefined &&
      !this.state.loadingPickUpPoints
    ) {
      return (
        <span>
          {translate(
            this.state.websiteLanguage,
            "CHECKOUT_SHIPPING_PLACEHOLDER_MESSAGE"
          )}
        </span>
      );
    } else if (this.state.loadingPickUpPoints) {
      return (
        <StageSpinner
          size={20}
          loading={this.state.loadingPickUpPoints}
          color={"#bcf135"}
        />
      );
    } else {
      return (
        <span>
          {shippingCost}
          {renderAmountDecimals(selectedCurrency)}
          {this.state.websiteLanguage === "no"
            ? translate(this.state.websiteLanguage, "CURRENCY_NORWEGIAN")
            : this.state.selectedCurrency}
        </span>
      );
    }
  };

  renderLongDistanceShippingAmount = (
    cartItems,
    country,
    shippingMethod,
    selectedCurrency,
    currencyRates,
    shippingPriceFromApi = undefined
  ) => {
    const shippingCost = shippingAmount(
      cartItems,
      country,
      shippingMethod,
      selectedCurrency,
      currencyRates,
      shippingPriceFromApi,
      this.state.discount,
      this.state.discountType
    );
    return (
      <span>
        {shippingCost}
        {renderAmountDecimals(selectedCurrency)}
        {this.state.websiteLanguage === "no"
          ? translate(this.state.websiteLanguage, "CURRENCY_NORWEGIAN")
          : this.state.selectedCurrency}
      </span>
    );
  };

  renderPostalCodeErrorMessage = (
    country,
    shippingPriceFromApiMailbox,
    validPostalCodeFromBringApi
  ) => {
    if (
      country === "Norge" &&
      shippingPriceFromApiMailbox === 0 &&
      validPostalCodeFromBringApi !== undefined &&
      !validPostalCodeFromBringApi &&
      this.checkPostalCode(this.state.postalCode)
    ) {
      return (
        <div className="postal-code-error-message">
          {translate(
            this.state.websiteLanguage,
            "CHECKOUT_FORM_VALIDATION_POSTALCODE"
          )}
        </div>
      );
    }
  };

  donationButtonSelected = (donationAmount) => {
    this.setState({ donationAmountSelected: donationAmount });
  };

  renderImportFeeDisclaimer = () => {
    return (
      <div className="import-fee-disclaimer">
        {translate(
          this.state.websiteLanguage,
          "CHECKOUT_IMPORT_FEE_DISCLAIMER"
        )}
      </div>
    );
  };

  render() {
    const {
      cartItems,
      modal,
      orderProcessing,
      showLongerDeliveryTime,
      country,
      comment,
      discount,
      discountType,
      shippingMethod,
      websiteLanguage,
      selectedCurrency,
      currenciesAvailable,
      currencyRates,
      showShippingTag,
      shippingPriceFromApiMailbox,
      loadingPriceFromApiMailbox,
      validPostalCodeFromBringApi,
      shippingPriceFromApiStore,
      donationAmountSelected,
      sendAbroad,
      paymentRequest,
      paymentWalletType,
    } = this.state;

    return (
      <React.Fragment>
        <Navbar
          showBackButton={true}
          websiteLanguage={this.state.websiteLanguage}
          showDiscountCode={this.showDiscountCode}
          transparent={false}
          backButtonTextRef={"HEADER_BACK"}
        />
        <section className="section-checkout">
          <div className="section-container">
            <h1>{translate(this.state.websiteLanguage, "CHECKOUT_HEADER")}</h1>
            {cartItems.length > 0 ? (
              <React.Fragment>
                {/* User cart */}
                <div className="checkout-cart">
                  {websiteLanguage !== "no" && currenciesAvailable > 0 && (
                    <div className="currency-bar">
                      <div className="currency-header">
                        {translate(
                          this.state.websiteLanguage,
                          "CHECKOUT_CURRENCY_HEADER"
                        )}
                      </div>
                      <div className="currency-selector">
                        <div
                          className={`currency-button${
                            this.state.selectedCurrency === "NOK"
                              ? " selected"
                              : ""
                          }`}
                          onClick={() =>
                            this.setState({
                              selectedCurrency: "NOK",
                            })
                          }
                        >
                          NOK
                        </div>
                        <div
                          className={`currency-button${
                            this.state.selectedCurrency === "USD"
                              ? " selected"
                              : ""
                          }`}
                          onClick={() =>
                            this.setState({
                              selectedCurrency: "USD",
                            })
                          }
                        >
                          USD
                        </div>
                        <div
                          className={`currency-button${
                            this.state.selectedCurrency === "EUR"
                              ? " selected"
                              : ""
                          }`}
                          onClick={() =>
                            this.setState({
                              selectedCurrency: "EUR",
                            })
                          }
                        >
                          EUR
                        </div>
                        <div
                          className={`currency-button${
                            this.state.selectedCurrency === "GBP"
                              ? " selected"
                              : ""
                          }`}
                          onClick={() =>
                            this.setState({
                              selectedCurrency: "GBP",
                            })
                          }
                        >
                          GBP
                        </div>
                        <div
                          className={`currency-button${
                            this.state.selectedCurrency === "SEK"
                              ? " selected"
                              : ""
                          }`}
                          onClick={() =>
                            this.setState({
                              selectedCurrency: "SEK",
                            })
                          }
                        >
                          SEK
                        </div>
                        <div
                          className={`currency-button${
                            this.state.selectedCurrency === "DKK"
                              ? " selected"
                              : ""
                          }`}
                          onClick={() =>
                            this.setState({
                              selectedCurrency: "DKK",
                            })
                          }
                        >
                          DKK
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="checkout-cart-container">
                    <div className="checkout-summary-header">
                      {translate(
                        this.state.websiteLanguage,
                        "CHECKOUT_SUMMARY_HEADER"
                      )}
                    </div>
                    <div className="checkout-cart-row-container">
                      {cartItems.map((item) => (
                        <div
                          className="checkout-cart-row"
                          key={item._id + item.name}
                        >
                          {item._id !== "customOrder" && item.quantity}
                          {item._id !== "customOrder" ? " " : ""}
                          {item.name}:{" "}
                          {itemSumAmount(
                            item.quantity,
                            item.price,
                            selectedCurrency,
                            currencyRates
                          )}
                          {renderAmountDecimals(selectedCurrency)}
                          {websiteLanguage === "no"
                            ? translate(
                                this.state.websiteLanguage,
                                "CURRENCY_NORWEGIAN"
                              )
                            : this.state.selectedCurrency}
                          {isRestrictedProduct(item, country) && (
                            <span className="cart-row-restricted-label">
                              {translate(
                                this.state.websiteLanguage,
                                "CHECKOUT_RESTRICTED_PRODUCT_LABEL"
                              )}
                            </span>
                          )}
                        </div>
                      ))}
                    </div>

                    {discount && (
                      <div className="checkout-cart-discount">
                        {translate(
                          this.state.websiteLanguage,
                          "CHECKOUT_DISCOUNT"
                        )}
                        :{" "}
                        {calculateDiscountCode(
                          cartItems,
                          discountType,
                          selectedCurrency,
                          currencyRates
                        )}
                        {renderAmountDecimals(selectedCurrency)}{" "}
                        {websiteLanguage === "no"
                          ? translate(
                              this.state.websiteLanguage,
                              "CURRENCY_NORWEGIAN"
                            )
                          : this.state.selectedCurrency}
                      </div>
                    )}
                    <div className="checkout-cart-subtotal">
                      {translate(websiteLanguage, "CART_SUBTOTAL_AMOUNT")}{" "}
                      {calculateAmount(
                        cartItems,
                        discount,
                        discountType,
                        selectedCurrency,
                        currencyRates
                      )}
                      {renderAmountDecimals(selectedCurrency)}
                      {websiteLanguage === "no"
                        ? translate(
                            this.state.websiteLanguage,
                            "CURRENCY_NORWEGIAN"
                          )
                        : this.state.selectedCurrency}
                    </div>
                    {this.state.showDiscountInput && (
                      <React.Fragment>
                        <div className="show-discount-input-header">
                          {translate(
                            this.state.websiteLanguage,
                            "CHECKOUT_FORM_DISCOUNT_CODE_HEADER"
                          )}
                        </div>
                        <div className="checkout-discount-code">
                          <input
                            id="discountCode"
                            type="text"
                            name="discountCode"
                            placeholder={translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_DISCOUNT_CODE"
                            )}
                            disabled={this.state.checkingDiscount}
                            onFocus={this.handleFocus}
                            onChange={this.handleChange.bind(this)}
                            value={this.state.discountCode}
                          />
                          <button
                            disabled={this.state.checkingDiscount}
                            onClick={this.checkDiscountCode}
                          >
                            {this.state.checkingDiscount ? (
                              <StageSpinner
                                loading={this.state.checkingDiscount}
                                color={"#000"}
                              />
                            ) : (
                              translate(
                                this.state.websiteLanguage,
                                "CHECKOUT_FORM_DISCOUNT_CODE_BUTTON"
                              )
                            )}
                          </button>
                        </div>
                        {this.state.discountMessage && (
                          <div
                            className={`discount-code-message ${
                              this.state.discount ? "active" : "invalid"
                            }`}
                          >
                            {this.state.discountMessage}
                          </div>
                        )}
                      </React.Fragment>
                    )}

                    {/* Shipping price */}
                    {(validPostalCodeFromBringApi || country !== "Norge") && (
                      <div className="checkout-cart-shipping">
                        {translate(
                          this.state.websiteLanguage,
                          "CHECKOUT_SHIPPING_AMOUNT"
                        )}
                        :{" "}
                        {shippingAmount(
                          cartItems,
                          country,
                          shippingMethod,
                          selectedCurrency,
                          currencyRates,
                          shippingMethod === "mailbox"
                            ? shippingPriceFromApiMailbox
                            : shippingPriceFromApiStore,
                          discount,
                          discountType
                        )}
                        {renderAmountDecimals(selectedCurrency)}
                        {websiteLanguage === "no"
                          ? translate(
                              this.state.websiteLanguage,
                              "CURRENCY_NORWEGIAN"
                            )
                          : this.state.selectedCurrency}
                      </div>
                    )}

                    {/* Donation line */}
                    {donationAmountSelected > 0 && (
                      <div className="checkout-cart-shipping">
                        {translate(websiteLanguage, "DONATION_LABEL")}:{" "}
                        {donationAmountSelected},00{" "}
                        {translate(websiteLanguage, "CURRENCY_NORWEGIAN")}
                      </div>
                    )}

                    {/* Total amount on top of checkout */}
                    {(validPostalCodeFromBringApi || country !== "Norge") && (
                      <div className="total-payment-amount-cart">
                        <div>
                          {translate(websiteLanguage, "TOTAL_AMOUNT")}
                          {": "}
                        </div>
                        <div>
                          {this.renderTotalPaymentAmount(
                            cartItems,
                            country,
                            discount,
                            discountType,
                            shippingMethod,
                            selectedCurrency,
                            currencyRates,
                            shippingMethod === "mailbox"
                              ? shippingPriceFromApiMailbox
                              : shippingPriceFromApiStore,
                            loadingPriceFromApiMailbox,
                            donationAmountSelected
                          )}
                        </div>
                      </div>
                    )}

                    {/* Import and customs fee disclaimer */}
                    {this.state.country !== "Norge" && (
                      <div className="cart-fee-dislaimer">
                        {this.renderImportFeeDisclaimer()}
                      </div>
                    )}
                  </div>

                  {/* Restricted items bar */}
                  {containsRestrictedProducts(
                    this.state.cartItems,
                    this.state.country
                  ) && (
                    <div className="cart-summary-restricted-bar">
                      <div>
                        {translate(
                          this.state.websiteLanguage,
                          "CHECKOUT_FORM_VALIDATION_RESTRICTED_PRODUCTS"
                        )}
                      </div>
                      <button
                        onClick={() => this.removeRestrictedProducts(cartItems)}
                        className="resticted-remove-button"
                      >
                        {" "}
                        {translate(
                          this.state.websiteLanguage,
                          "CHECKOUT_REMOVE_RESTRICTED_PRODUCTS_BUTTON"
                        )}
                      </button>
                    </div>
                  )}
                </div>

                {/* Skjema */}
                <form
                  onSubmit={this.handleConfirmOrder}
                  className="checkout-form"
                >
                  <div className="checkout-form-heading">
                    {translate(
                      this.state.websiteLanguage,
                      "CHECKOUT_FORM_INSTRUCTIONS"
                    )}
                  </div>
                  <div className="form-label-big">
                    {translate(
                      this.state.websiteLanguage,
                      "CHECKOUT_FORM_NAME"
                    )}
                  </div>
                  {/* Navnefelt */}
                  <div className="name-fields">
                    <input
                      id="firstName"
                      type="text"
                      name="firstName"
                      placeholder={translate(
                        this.state.websiteLanguage,
                        "CHECKOUT_FORM_FIRSTNAME_PLACEHOLDER"
                      )}
                      onFocus={this.handleFocus}
                      onChange={this.handleChange.bind(this)}
                      value={this.state.firstName}
                    />
                    <input
                      id="lastName"
                      type="text"
                      name="lastName"
                      placeholder={translate(
                        this.state.websiteLanguage,
                        "CHECKOUT_FORM_LASTNAME_PLACEHOLDER"
                      )}
                      onFocus={this.handleFocus}
                      onChange={this.handleChange.bind(this)}
                      value={this.state.lastName}
                    />
                  </div>

                  {/* Bekreftelses e-post */}

                  <div className="form-label-big">
                    {translate(
                      this.state.websiteLanguage,
                      "CHECKOUT_FORM_EMAIL"
                    )}
                  </div>
                  <input
                    id="confirmationEmailAddress"
                    type="text"
                    name="confirmationEmailAddress"
                    placeholder={translate(
                      this.state.websiteLanguage,
                      "CHECKOUT_FORM_EMAIL_PLACEHOLDER"
                    )}
                    onFocus={this.handleFocus}
                    onChange={this.handleChange.bind(this)}
                    value={this.state.confirmationEmailAddress}
                  />

                  <div className="form-label-big header-with-checkbox">
                    {translate(
                      this.state.websiteLanguage,
                      "CHECKOUT_FORM_ADDRESS"
                    )}
                    {this.state.websiteLanguage === "no" && (
                      <div className="checkbox-container">
                        <label className="send-abroad" htmlFor="sendAbroad">
                          <input
                            type="checkbox"
                            checked={this.state.sendAbroad}
                            className="checkout-checkbox"
                            id="sendAbroad"
                            name="sendAbroad"
                            onFocus={this.handleFocus}
                            onChange={() => {
                              this.setState({
                                sendAbroad: !this.state.sendAbroad,
                                country: this.state.sendAbroad && "Norge",
                              });
                            }}
                          />
                          <span className="checkmark" />
                          {translate(
                            this.state.websiteLanguage,
                            "CHECKOUT_FORM_SEND_ABROAD"
                          )}
                        </label>
                      </div>
                    )}
                  </div>

                  {/* Adressefelt */}
                  {/* Land */}
                  {(this.state.websiteLanguage !== "no" ||
                    this.state.sendAbroad) && (
                    <React.Fragment>
                      <div className="country-dropdown">
                        <select
                          id="country"
                          type="text"
                          name="country"
                          onFocus={this.handleFocus}
                          onChange={this.handleChange.bind(this)}
                          defaultValue={"default"}
                        >
                          {this.state.sendAbroad ? (
                            <option value="default" disabled>
                              {translate(
                                this.state.websiteLanguage,
                                "CHECKOUT_FORM_COUNTRY_SELECT"
                              )}
                            </option>
                          ) : (
                            <option value="Norge">
                              {translate(
                                this.state.websiteLanguage,
                                "CHECKOUT_FORM_COUNTRY_NORWAY"
                              )}
                            </option>
                          )}
                          <option value="Sverige">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_SWEDEN"
                            )}
                          </option>
                          <option value="Danmark">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_DENMARK"
                            )}
                          </option>
                          <option value="Ukraine">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_UKRAINE"
                            )}
                          </option>
                          <option value="Canada">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_CANADA"
                            )}
                          </option>
                          <option value="USA">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_USA"
                            )}
                          </option>
                          <option value="Finland">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_FINLAND"
                            )}
                          </option>
                          <option value="United Kingdom">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_UK"
                            )}
                          </option>
                          <option value="Germany">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_GERMANY"
                            )}
                          </option>
                          <option value="Belgium">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_BELGIUM"
                            )}
                          </option>
                          <option value="Netherlands">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_HOLLAND"
                            )}
                          </option>
                          <option value="France">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_FRANCE"
                            )}
                          </option>
                          <option value="Spain">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_SPAIN"
                            )}
                          </option>
                          <option value="Portugal">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_PORTUGAL"
                            )}
                          </option>
                          <option value="Italy">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_ITALY"
                            )}
                          </option>
                          <option value="Poland">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_POLAND"
                            )}
                          </option>
                          <option value="Australia">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_AUSTRALIA"
                            )}
                          </option>
                          <option value="NewZealand">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_NEW_ZEALAND"
                            )}
                          </option>
                          <option value="Latvia">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_LATVIA"
                            )}
                          </option>
                          <option value="Lithuania">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_LITHUANIA"
                            )}
                          </option>
                          <option value="Estonia">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_ESTONIA"
                            )}
                          </option>
                          <option value="Slovakia">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_SLOVAKIA"
                            )}
                          </option>
                          <option value="Czech">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_CZECH"
                            )}
                          </option>
                          <option value="Ireland">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_IRELAND"
                            )}
                          </option>
                          <option value="Japan">
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_COUNTRY_JAPAN"
                            )}
                          </option>
                        </select>
                        <div className="select-dropdown-arrow" />
                        <div
                          className={`shipping-price-updated-tag${
                            showShippingTag ? " visible" : " hidden"
                          }`}
                        >
                          {translate(
                            this.state.websiteLanguage,
                            "SHIPPING_PRICE_UPDATED"
                          )}
                        </div>
                      </div>
                      {containsRestrictedProducts(cartItems, country) && (
                        <div className="restricted-products-warning">
                          {translate(
                            this.state.websiteLanguage,
                            "CHECKOUT_FORM_RESTRICTED_PRODUCTS_WARNING"
                          )}{" "}
                          <span
                            className="restricted-products-warning-button"
                            onClick={() =>
                              document
                                .querySelector(".checkout-cart")
                                .scrollIntoView({
                                  behavior: "smooth",
                                  block: "start",
                                })
                            }
                          >
                            {translate(
                              this.state.websiteLanguage,
                              "CHECKOUT_FORM_RESTRICTED_PRODUCTS_WARNING_BUTTON"
                            )}
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  <input
                    id="address"
                    type="text"
                    name="address"
                    placeholder={translate(
                      this.state.websiteLanguage,
                      "CHECKOUT_FORM_ADDRESS_PLACEHOLDER"
                    )}
                    onFocus={this.handleFocus}
                    onChange={this.handleChange.bind(this)}
                    value={this.state.address}
                  />
                  <div className="postalcode-city">
                    {/* Postnummer */}
                    <input
                      id="postalCode"
                      type="text"
                      name="postalCode"
                      className={`${
                        country === "Norge" &&
                        shippingPriceFromApiMailbox === 0 &&
                        validPostalCodeFromBringApi !== undefined &&
                        !validPostalCodeFromBringApi &&
                        this.checkPostalCode(this.state.postalCode)
                          ? "validation-error"
                          : ""
                      }`}
                      placeholder={translate(
                        this.state.websiteLanguage,
                        "CHECKOUT_FORM_POSTALCODE_PLACEHOLDER"
                      )}
                      onFocus={this.handleFocus}
                      onChange={this.handleChange.bind(this)}
                      value={this.state.postalCode}
                      onBlur={() => this.postalCodeFieldOnBlur()}
                    />

                    {/* Poststed */}
                    <input
                      id="city"
                      type="text"
                      name="city"
                      placeholder={translate(
                        this.state.websiteLanguage,
                        "CHECKOUT_FORM_CITY_PLACEHOLDER"
                      )}
                      onFocus={this.handleFocus}
                      onChange={this.handleChange.bind(this)}
                      value={this.state.city}
                    />
                  </div>

                  {/* Stat */}
                  {(country === "USA" ||
                    country === "Canada" ||
                    country === "Australia") && (
                    <input
                      id="state"
                      type="text"
                      name="state"
                      placeholder={translate(
                        this.state.websiteLanguage,
                        "CHECKOUT_FORM_STATE_PLACEHOLDER"
                      )}
                      onFocus={this.handleFocus}
                      onChange={this.handleChange.bind(this)}
                      value={this.state.state}
                    />
                  )}

                  {/* Postal code validation message */}
                  {this.renderPostalCodeErrorMessage(
                    country,
                    shippingPriceFromApiMailbox,
                    validPostalCodeFromBringApi
                  )}

                  <div className="form-label-big">
                    {/* Phone input */}
                    {country === "USA" || country === "Canada"
                      ? translate(
                          this.state.websiteLanguage,
                          "CHECKOUT_FORM_PHONE_AMERICA"
                        )
                      : translate(
                          this.state.websiteLanguage,
                          "CHECKOUT_FORM_PHONE"
                        )}
                  </div>

                  <input
                    id="phone"
                    name="phone"
                    type="tel"
                    placeholder={translate(
                      this.state.websiteLanguage,
                      "CHECKOUT_FORM_PHONE_PLACEHOLDER"
                    )}
                    onFocus={this.handleFocus}
                    onChange={this.handleChange.bind(this)}
                    value={this.state.phone}
                  />

                  <input
                    id="comment"
                    name="comment"
                    type="text"
                    placeholder={
                      cartContainsCallsignsPack(cartItems)
                        ? translate(
                            this.state.websiteLanguage,
                            "CHECKOUT_FORM_COMMENT_PLACEHOLDER_CALLSIGNS"
                          )
                        : translate(
                            this.state.websiteLanguage,
                            "CHECKOUT_FORM_COMMENT_PLACEHOLDER"
                          )
                    }
                    onFocus={this.handleFocus}
                    onChange={this.handleChange.bind(this)}
                    value={comment}
                  />

                  {/* Shipping method selector */}
                  {showExtendedShippingOptions(
                    country,
                    websiteLanguage,
                    sendAbroad
                  ) &&
                    !isLongDistanceShipping(country) && (
                      <div className="shipping-method-section">
                        <div className="form-label-big">
                          {translate(
                            this.state.websiteLanguage,
                            "CHECKOUT_FORM_SHIPPING_METHOD"
                          )}
                        </div>
                        {/* Mailbox option */}
                        {!cartContainsPickupPointOnlyItem(cartItems) && (
                          <div
                            className={`pay-method-btn mailbox ${
                              this.state.validPostalCodeFromBringApi
                                ? "active"
                                : "inactive"
                            } ${
                              this.state.shippingMethod === "mailbox"
                                ? "enabled"
                                : "disabled"
                            } `}
                            onClick={() =>
                              this.setState({ shippingMethod: "mailbox" })
                            }
                          >
                            <div className="shipping-type">
                              <span className="checkout-shipping-placeholder-message">
                                {translate(
                                  this.state.websiteLanguage,
                                  "CHECKOUT_SHIPPING_MAILBOX_BTN"
                                )}
                                {":"}
                              </span>
                              {this.renderMailboxShippingAmount(
                                cartItems,
                                country,
                                "mailbox",
                                selectedCurrency,
                                currencyRates,
                                shippingPriceFromApiMailbox,
                                loadingPriceFromApiMailbox
                              )}
                            </div>
                            <div className="shipping-description">
                              {translate(
                                this.state.websiteLanguage,
                                "SHIPPING_TIME_MAILBOX"
                              )}{" "}
                              {`${translate(
                                this.state.websiteLanguage,
                                "SHIPPING_TIME_PREFIX"
                              )}: ${this.setBringDeliveryTime()} ${translate(
                                this.state.websiteLanguage,
                                "SHIPPING_TIME_DAYS"
                              )}`}
                            </div>
                          </div>
                        )}

                        {/* Pickup in store option */}
                        <div
                          className={`pay-method-btn store ${
                            this.state.validPostalCodeFromBringApi
                              ? "active"
                              : "inactive"
                          } ${
                            this.state.shippingMethod === "store"
                              ? "enabled"
                              : "disabled"
                          } ${
                            cartContainsPickupPointOnlyItem(cartItems)
                              ? "topborder"
                              : "notopborder"
                          }`}
                          onClick={() => this.onSelectShppingToStore()}
                        >
                          <div className="shipping-type">
                            <span className="checkout-shipping-placeholder-message">
                              {translate(
                                this.state.websiteLanguage,
                                "CHECKOUT_SHIPPING_STORE_BTN"
                              )}
                              {":"}
                            </span>
                            {this.renderStoreShippingAmount(
                              cartItems,
                              country,
                              "store",
                              selectedCurrency,
                              currencyRates,
                              shippingPriceFromApiStore
                            )}
                          </div>
                          <div className="shipping-description">{`${translate(
                            this.state.websiteLanguage,
                            "SHIPPING_TIME_PREFIX"
                          )}: ${this.setPostnordDeliveryTime()} ${translate(
                            this.state.websiteLanguage,
                            "SHIPPING_TIME_DAYS"
                          )}`}</div>
                          {this.state.postnordStrike &&
                            this.state.shippingMethod === "store" && (
                              <div className="strike-warning">
                                {translate(
                                  this.state.websiteLanguage,
                                  "STRIKE_WARNING"
                                )}
                              </div>
                            )}
                        </div>
                        <div>
                          <StageSpinner
                            loading={
                              this.state.shippingMethod === "store" &&
                              this.state.loadingPickUpPoints
                            }
                            color={"#bcf135"}
                          />
                          {this.renderPickupLocations()}
                        </div>
                      </div>
                    )}

                  {/* Shipping method selector long distance shipping*/}
                  {isLongDistanceShipping(country) && (
                    <div className="shipping-method-section">
                      <div className="form-label-big">
                        {translate(
                          this.state.websiteLanguage,
                          "CHECKOUT_FORM_SHIPPING_METHOD"
                        )}
                      </div>
                      {/* Standard Mail option */}
                      <div
                        className={`pay-method-btn long-distance-standard ${
                          this.state.shippingMethod ===
                          "longDistanceStandardMail"
                            ? "enabled"
                            : "disabled"
                        }`}
                        onClick={() =>
                          this.setState({
                            shippingMethod: "longDistanceStandardMail",
                          })
                        }
                      >
                        <div className="shipping-type">
                          <span className="checkout-shipping-placeholder-message">
                            Standard Mail
                            {":"}
                          </span>
                          {this.renderLongDistanceShippingAmount(
                            cartItems,
                            country,
                            "longDistanceStandardMail",
                            selectedCurrency,
                            currencyRates
                          )}
                        </div>
                        <div className="shipping-description">
                          {`${translate(
                            this.state.websiteLanguage,
                            "SHIPPING_TIME_PREFIX_LONG_DISTANCE_STANDARD"
                          )}: ${this.setLongDistanceDeliveryTime(
                            "longDistanceStandardMail"
                          )} ${translate(
                            this.state.websiteLanguage,
                            "SHIPPING_TIME_DAYS"
                          )}`}
                        </div>
                      </div>
                      {/* UPS option */}
                      <div
                        className={`pay-method-btn long-distance-ups ${
                          this.state.shippingMethod === "longDistanceUps"
                            ? "enabled"
                            : "disabled"
                        }`}
                        onClick={() =>
                          this.setState({ shippingMethod: "longDistanceUps" })
                        }
                      >
                        <div className="shipping-type">
                          <span className="checkout-shipping-placeholder-message">
                            UPS Expedited
                            {":"}
                          </span>
                          {this.renderLongDistanceShippingAmount(
                            cartItems,
                            country,
                            "longDistanceUps",
                            selectedCurrency,
                            currencyRates
                          )}
                        </div>
                        <div className="shipping-description">{`${translate(
                          this.state.websiteLanguage,
                          "SHIPPING_TIME_PREFIX_LONG_DISTANCE_UPS"
                        )}: ${this.setLongDistanceDeliveryTime(
                          "longDistanceUps"
                        )} ${translate(
                          this.state.websiteLanguage,
                          "SHIPPING_TIME_DAYS"
                        )}`}</div>
                        {this.state.postnordStrike &&
                          this.state.shippingMethod === "store" && (
                            <div className="strike-warning">
                              {translate(
                                this.state.websiteLanguage,
                                "STRIKE_WARNING"
                              )}
                            </div>
                          )}
                      </div>
                      <div>
                        <StageSpinner
                          loading={
                            this.state.shippingMethod === "store" &&
                            this.state.loadingPickUpPoints
                          }
                          color={"#bcf135"}
                        />
                        {this.renderPickupLocations()}
                      </div>
                    </div>
                  )}

                  {/* Donation section */}
                  {(country === "Norge" || sendAbroad) && (
                    <DonationSection
                      donationButtonSelected={this.donationButtonSelected}
                      donationAmountSelected={this.state.donationAmountSelected}
                      websiteLanguage={this.state.websiteLanguage}
                      sendAbroad={this.state.sendAbroad}
                      country={this.state.country}
                      validPostalCodeSubmitted={
                        this.state.validPostalCodeFromBringApi
                      }
                    />
                  )}

                  {/* Total amount including shipping view */}
                  {country !== "Norge" && (
                    <div className="shipping-payment-amount">
                      <div>{translate(websiteLanguage, "SHIPPING_AMOUNT")}</div>
                      <div>
                        {shippingAmount(
                          cartItems,
                          country,
                          shippingMethod,
                          selectedCurrency,
                          currencyRates,
                          undefined,
                          discount,
                          discountType
                        )}
                        {renderAmountDecimals(selectedCurrency)}
                        {this.state.websiteLanguage === "no"
                          ? translate(
                              this.state.websiteLanguage,
                              "CURRENCY_NORWEGIAN"
                            )
                          : this.state.selectedCurrency}
                      </div>
                    </div>
                  )}
                  <div className="total-payment-amount">
                    <div>
                      {validPostalCodeFromBringApi
                        ? `${translate(websiteLanguage, "TOTAL_AMOUNT")}:`
                        : translate(websiteLanguage, "CART_SUBTOTAL_AMOUNT")}
                    </div>
                    <div>
                      {this.renderTotalPaymentAmount(
                        cartItems,
                        country,
                        discount,
                        discountType,
                        shippingMethod,
                        selectedCurrency,
                        currencyRates,
                        shippingMethod === "mailbox"
                          ? shippingPriceFromApiMailbox
                          : shippingPriceFromApiStore,
                        loadingPriceFromApiMailbox,
                        donationAmountSelected
                      )}
                    </div>
                  </div>

                  {/* Customs and import fee disclaimer */}
                  {country !== "Norge" && (
                    <div className="mobile-fee-dislaimer">
                      {this.renderImportFeeDisclaimer()}
                    </div>
                  )}

                  {showLongerDeliveryTime && (
                    <div className="extra-delivery-time">
                      {translate(
                        this.state.websiteLanguage,
                        "EXTRA_DELIVERY_TIME"
                      )}
                    </div>
                  )}

                  {/* Card, Vipps and Gift Card selector */}
                  {showExtendedPaymentOptions(
                    country,
                    websiteLanguage,
                    sendAbroad,
                    paymentWalletType
                  ) && (
                    <React.Fragment>
                      <div className="form-label-big payment-options">
                        {translate(
                          this.state.websiteLanguage,
                          "CHECKOUT_FORM_PAYMENT_METHOD"
                        )}
                      </div>
                      <div
                        className={`pay-method-btn card ${
                          this.state.paymentMethod === "card"
                            ? "enabled"
                            : "disabled"
                        }`}
                        onClick={() => this.setState({ paymentMethod: "card" })}
                      >
                        {translate(
                          this.state.websiteLanguage,
                          "CHECKOUT_PAY_CARD_BTN"
                        )}
                      </div>
                      {showVippsPaymentOption(
                        country,
                        websiteLanguage,
                        sendAbroad
                      ) && (
                        <div
                          className={`pay-method-btn vipps ${
                            this.state.paymentMethod === "vipps"
                              ? "enabled"
                              : "disabled"
                          }`}
                          onClick={() =>
                            this.setState({ paymentMethod: "vipps" })
                          }
                        >
                          {translate(
                            this.state.websiteLanguage,
                            "CHECKOUT_PAY_VIPPS_BTN"
                          )}
                        </div>
                      )}
                      {paymentRequest &&
                        (paymentWalletType === "applePay" ||
                          paymentWalletType === "googlePay") && (
                          <div
                            className={`pay-method-btn giftcard ${
                              this.state.paymentMethod === "giftcard"
                                ? "enabled"
                                : "disabled"
                            }`}
                            onClick={() => {
                              this.setState({ paymentMethod: "giftcard" });
                            }}
                          >
                            {paymentWalletType === "applePay" ? (
                              <span>Apple Pay</span>
                            ) : (
                              <span>Google Pay</span>
                            )}
                          </div>
                        )}
                    </React.Fragment>
                  )}

                  {/* Stripe Card Element */}
                  {this.state.paymentMethod === "card" && (
                    <React.Fragment>
                      <div className="form-label-big payment-input-card-label">
                        {translate(
                          this.state.websiteLanguage,
                          "CHECKOUT_FORM_CARD"
                        )}
                      </div>
                      <CardElement
                        id="stripe__input"
                        onChange={(e) => this.setComplete(e.complete)}
                        onFocus={this.handleFocus}
                        iconStyle={"solid"}
                        options={CARD_ELEMENT_OPTIONS}
                      />
                    </React.Fragment>
                  )}

                  <div className="form-label-big purchase-agreement-label">
                    {translate(
                      this.state.websiteLanguage,
                      "PURCHASE_AGREEMENT"
                    )}
                  </div>
                  <div className="checkbox-container">
                    <label
                      className="accept-purchase-agreement-label"
                      htmlFor="purchaseAgreement"
                    >
                      <input
                        type="checkbox"
                        checked={this.state.agreementChecked}
                        className="checkout-checkbox"
                        id="purchaseAgreement"
                        name="purchaseAgreement"
                        onFocus={this.handleFocus}
                        onChange={() => {
                          this.setState({
                            agreementChecked: !this.state.agreementChecked,
                          });
                        }}
                      />
                      <span className="checkmark" />
                      {translate(
                        this.state.websiteLanguage,
                        "CHECKOUT_FORM_ACCEPT"
                      )}
                      <span
                        className="accept-purchase-agreement-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.setState(
                            {
                              showAgreement: true,
                              scrollPos: window.scrollY,
                            },
                            () =>
                              document
                                .querySelector("html")
                                .setAttribute("style", "overflow: hidden;")
                          );
                        }}
                      >
                        {translate(
                          this.state.websiteLanguage,
                          "CHECKOUT_FORM_AGREEMENT"
                        )}
                      </span>
                    </label>
                  </div>

                  {/* Confirmation error message */}
                  <div
                    className={`order-error-dialog ${
                      this.state.showOrderError ? "visible" : "hidden"
                    }`}
                  >
                    <p>
                      {translate(
                        this.state.websiteLanguage,
                        "CHECKOUT_FORM_GENERIC_ERROR"
                      )}
                    </p>
                  </div>

                  {/* Confirm order button */}
                  <button
                    color="blue"
                    id="stripe__button"
                    type="submit"
                    className={`checkout-proceed-button`}
                  >
                    {translate(
                      this.state.websiteLanguage,
                      "CHECKOUT_FORM_PROCEED"
                    )}
                  </button>

                  <div
                    className={`checkout-form-validation-message ${
                      this.state.errorMessage ? "visible" : "hidden"
                    }`}
                  >
                    <p>
                      {this.state.errorMessage}{" "}
                      {this.state.showRemoveRestrictedProductsButton && (
                        <span
                          className="remove-restricted-items-button"
                          onClick={() =>
                            document
                              .querySelector(".checkout-cart")
                              .scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                              })
                          }
                        >
                          {translate(
                            this.state.websiteLanguage,
                            "CHECKOUT_FORM_RESTRICTED_PRODUCTS_WARNING_BUTTON"
                          )}
                        </span>
                      )}
                    </p>
                  </div>
                </form>
                {/* Purchase agreement */}
                <Agreement
                  showAgreement={this.state.showAgreement}
                  toggleAgreementVisibility={this.toggleAgreementVisibility}
                  websiteLanguage={this.state.websiteLanguage}
                />
              </React.Fragment>
            ) : (
              <div>
                {translate(this.state.websiteLanguage, "CHECKOUT_EMPTY_CART")}
              </div>
            )}
            <div
              className={`confirmation-modal ${modal ? "visible" : "hidden"}`}
            >
              <ConfirmationModal
                orderProcessing={orderProcessing}
                cartItems={cartItems}
                closeModal={this.closeModal}
                handleSubmitOrder={this.handleSubmitOrder}
                websiteLanguage={this.state.websiteLanguage}
                paymentMethod={this.state.paymentMethod}
                initiateVippsPayment={this.initiateVippsPayment}
                redirectingToVipps={this.state.redirectingToVipps}
                initiateGiftCardOrder={this.initiateGiftCardOrder}
                country={this.state.country}
                discount={this.state.discount}
                discountType={this.state.discountType}
                shippingMethod={this.state.shippingMethod}
                selectedCurrency={this.state.selectedCurrency}
                currencyRates={this.state.currencyRates}
                shippingPriceFromApiMailbox={
                  this.state.shippingPriceFromApiMailbox
                }
                shippingPriceFromApiStore={this.state.shippingPriceFromApiStore}
                donationAmountSelected={this.state.donationAmountSelected}
                paymentRequest={this.state.paymentRequest}
                updatePaymentRequestInstance={this.updatePaymentRequestInstance}
              />
            </div>
            {/* Confirmation dialog */}
            <div
              className={`order-confirmation-dialog ${
                this.state.showOrderConfirmation ? "visible" : "hidden"
              }`}
            >
              {this.state.vippsError ? (
                <div>
                  <div className="order-confirmation-dialog-content center">
                    <div className="error-symbol">:/</div>
                    <div>
                      {translate(
                        this.state.websiteLanguage,
                        "ORDER_VIPPS_ERROR"
                      )}
                    </div>
                    <button
                      className="frontpage-link"
                      onClick={() => {
                        this.goToFrontPage();
                      }}
                    >
                      {translate(
                        this.state.websiteLanguage,
                        "ORDER_CONFIRMATION_LINK"
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="order-confirmation-dialog-content">
                  <div
                    className={`text-mask ${
                      this.state.websiteLanguage === "en" ? "english" : "other"
                    }`}
                  >
                    {translate(this.state.websiteLanguage, "BOOM")}
                  </div>
                  <h1>
                    {translate(
                      this.state.websiteLanguage,
                      "ORDER_CONFIRMATION_HEADER"
                    )}
                  </h1>
                  {this.state.showVippsConfirmationStatus ? (
                    <div className="confirmation-email-sent">
                      {translate(
                        this.state.websiteLanguage,
                        "ORDER_VIPPS_MESSAGE"
                      )}
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="confirmation-email-sent">
                        {translate(
                          this.state.websiteLanguage,
                          "ORDER_CONFIRMATION_EMAIL"
                        )}
                        : {this.state.confirmationEmailAddress.toLowerCase()}
                      </div>
                      <div className="spam-folder-disclaimer">
                        {translate(
                          this.state.websiteLanguage,
                          "ORDER_CONFIRMATION_SPAM_DISCLAIMER"
                        )}
                      </div>
                      {/*<div className="order-summary-header">
                        {translate(
                          this.state.websiteLanguage,
                          "ORDER_CONFIRMATION_SUMMARY"
                        )}
                        :
                      </div>
                      <div className="ordered-items-summary">
                        {summaryItems.map((item) => (
                          <div className="item-row" key={item._id + item.name}>
                            {item._id !== "customOrder" && item.quantity}
                            {item._id !== "customOrder" ? " " : ""}
                            {item.name}
                          </div>
                        ))}
                      </div>
                      <div className="checkout-cart-total">
                        {translate(this.state.websiteLanguage, "TOTAL_AMOUNT")}:{" "}
                        {totalAmount(
                          summaryItems,
                          country,
                          discount,
                          discountType,
                          shippingMethod,
                          selectedCurrency,
                          currencyRates,
                          shippingMethod === "mailbox"
                            ? shippingPriceFromApiMailbox
                            : shippingPriceFromApiStore
                        )}
                        {renderAmountDecimals(selectedCurrency)}
                        {websiteLanguage === "no"
                          ? translate(
                              this.state.websiteLanguage,
                              "CURRENCY_NORWEGIAN"
                            )
                          : this.state.selectedCurrency}
                      </div>
                          */}
                      {this.state.remainingGiftcardCredits && (
                        <div className="confirmation-email-sent">
                          {translate(
                            this.state.websiteLanguage,
                            "ORDER_CONFIRMATION_GIFTCARD_REMAINING"
                          )}{" "}
                          {this.state.remainingGiftcardCredits}{" "}
                          {websiteLanguage === "no"
                            ? translate(
                                this.state.websiteLanguage,
                                "CURRENCY_NORWEGIAN"
                              )
                            : this.state.selectedCurrency}
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  {this.state.donationAmountSelected > 0 && (
                    <div className="confirmation-email-sent">
                      {translate(websiteLanguage, "DONATION_GREETING")}
                    </div>
                  )}
                  <a
                    className="instagram-link"
                    href="https://www.instagram.com/3charlie.no/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="instagram logo"
                      src="./icons/instagram-icon.svg"
                    />
                    {translate(this.state.websiteLanguage, "INSTAGRAM_LINK")}
                  </a>
                  <div className="instagram-feed">
                    <InstagramFeed token={instagramToken} counter="2" />
                  </div>
                  <button
                    className="frontpage-link"
                    onClick={() => {
                      this.goToFrontPage();
                    }}
                  >
                    {translate(
                      this.state.websiteLanguage,
                      "ORDER_CONFIRMATION_LINK"
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export const ConfirmationModal = ({
  orderProcessing,
  cartItems,
  closeModal,
  handleSubmitOrder,
  websiteLanguage,
  paymentMethod,
  initiateVippsPayment,
  redirectingToVipps,
  initiateGiftCardOrder,
  country,
  discount,
  discountType,
  shippingMethod,
  selectedCurrency,
  currencyRates,
  shippingPriceFromApiMailbox,
  shippingPriceFromApiStore,
  donationAmountSelected,
  paymentRequest,
  updatePaymentRequestInstance,
}) => (
  <React.Fragment>
    {/* Order summary */}
    <div className="order-confirmation-modal-header" onClick={closeModal} />
    <div className="order-confirmation-modal">
      <div className="order-confirmation-modal-content">
        {!orderProcessing ? (
          <React.Fragment>
            <h3>{translate(websiteLanguage, "ORDER_CONFIRM_DIALOG_HEADER")}</h3>
            <div className="order-confirmation-modal-item-rows">
              {cartItems.map((item) => (
                <div
                  className="item-row"
                  key={item._id + item.name}
                  padding={2}
                >
                  {item._id !== "customOrder" && item.quantity}
                  {item._id !== "customOrder" ? " " : ""}
                  {item.name}:{" "}
                  {itemSumAmount(
                    item.quantity,
                    item.price,
                    selectedCurrency,
                    currencyRates
                  )}
                  {renderAmountDecimals(selectedCurrency)}
                  {websiteLanguage === "no"
                    ? translate(websiteLanguage, "CURRENCY_NORWEGIAN")
                    : selectedCurrency}
                </div>
              ))}
            </div>
            <div className="order-confirmation-modal-footer">
              <div className="shipping">
                {translate(websiteLanguage, "SHIPPING_AMOUNT")}{" "}
                {shippingAmount(
                  cartItems,
                  country,
                  shippingMethod,
                  selectedCurrency,
                  currencyRates,
                  shippingMethod === "mailbox"
                    ? shippingPriceFromApiMailbox
                    : shippingPriceFromApiStore,
                  discount,
                  discountType
                )}
                {renderAmountDecimals(selectedCurrency)}
                {websiteLanguage === "no"
                  ? translate(websiteLanguage, "CURRENCY_NORWEGIAN")
                  : selectedCurrency}
              </div>
              {donationAmountSelected > 0 && (
                <div className="shipping">
                  {translate(websiteLanguage, "DONATION_LABEL")}:{" "}
                  {donationAmountSelected},00{" "}
                  {translate(websiteLanguage, "CURRENCY_NORWEGIAN")}
                </div>
              )}
              {discount && (
                <div className="discount">
                  {translate(websiteLanguage, "CHECKOUT_DISCOUNT")}:{" "}
                  {calculateDiscountCode(
                    cartItems,
                    discountType,
                    selectedCurrency,
                    currencyRates
                  )}
                  {renderAmountDecimals(selectedCurrency)}{" "}
                  {websiteLanguage === "no"
                    ? translate(websiteLanguage, "CURRENCY_NORWEGIAN")
                    : selectedCurrency}{" "}
                </div>
              )}
              <div className="order-confirmation-modal-total">
                {translate(websiteLanguage, "TOTAL_AMOUNT")}:{" "}
                {totalAmount(
                  cartItems,
                  country,
                  discount,
                  discountType,
                  shippingMethod,
                  selectedCurrency,
                  currencyRates,
                  shippingMethod === "mailbox"
                    ? shippingPriceFromApiMailbox
                    : shippingPriceFromApiStore,
                  donationAmountSelected
                )}
                {renderAmountDecimals(selectedCurrency)}{" "}
                {websiteLanguage === "no"
                  ? translate(websiteLanguage, "CURRENCY_NORWEGIAN")
                  : selectedCurrency}
              </div>
              {country === "Norge" && (
                <div className="vat">
                  {translate(websiteLanguage, "ORDER_CONFIRM_DIALOG_VAT")}:{" "}
                  {vatTotalAmount(
                    cartItems,
                    country,
                    discount,
                    discountType,
                    shippingMethod,
                    selectedCurrency,
                    currencyRates,
                    shippingMethod === "mailbox"
                      ? shippingPriceFromApiMailbox
                      : shippingPriceFromApiStore
                  )}
                  {renderAmountDecimals(selectedCurrency)}
                  {websiteLanguage === "no"
                    ? translate(websiteLanguage, "CURRENCY_NORWEGIAN")
                    : selectedCurrency}
                </div>
              )}
              {selectedCurrency !== "NOK" && (
                <div className="nok">
                  {translate(
                    websiteLanguage,
                    "ORDER_CONFIRM_DIALOG_NOK_CHARGE"
                  )}
                </div>
              )}
              <div className="order-confirmation-modal-buttons">
                {paymentMethod === "vipps" ? (
                  <button
                    className="confirm-buy-button-vipps"
                    disabled={orderProcessing}
                    onClick={initiateVippsPayment}
                  >
                    {websiteLanguage === "en" ? (
                      <img alt="close" src="./icons/vipps-btn-eng.svg" />
                    ) : (
                      <img alt="close" src="./icons/vipps-btn.svg" />
                    )}
                  </button>
                ) : paymentMethod === "card" ? (
                  <button
                    className="confirm-buy-button-card"
                    disabled={orderProcessing}
                    onClick={handleSubmitOrder}
                  >
                    {translate(websiteLanguage, "ORDER_CONFIRM_DIALOG_BUY")}
                  </button>
                ) : (
                  paymentMethod === "giftcard" &&
                  paymentRequest && (
                    <PaymentRequestButtonElement
                      options={{
                        paymentRequest,
                        style: { paymentRequestButton: { theme: "light" } },
                      }}
                      onClick={updatePaymentRequestInstance}
                    />
                  )
                )}
                <button
                  className="confirm-cancel-button"
                  disabled={orderProcessing}
                  onClick={closeModal}
                >
                  {translate(websiteLanguage, "ORDER_CONFIRM_DIALOG_CANCEL")}
                </button>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className="order-processing">
            <StageSpinner loading={orderProcessing} color="#bcf135" />
            <div className="confirming-text">
              {redirectingToVipps ? (
                translate(
                  websiteLanguage,
                  "ORDER_CONFIRM_DIALOG_REDIRECT_VIPPS"
                )
              ) : (
                <div>
                  <div>
                    {translate(
                      websiteLanguage,
                      "ORDER_CONFIRM_DIALOG_PROCESSING"
                    )}
                  </div>
                  <div className="payment-completion-disclaimer">
                    {translate(
                      websiteLanguage,
                      "ORDER_CONFIRM_DIALOG_PROCESSING_VIPPS"
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  </React.Fragment>
);

const CheckoutForm = withRouter(_CheckoutForm);

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
